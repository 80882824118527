'use client'
import { useState } from 'react'
import { useTranslation } from 'app/i18n/client'

import { Modal, ModalHeader } from 'app/components/atoms'

import SpecialConditionPanel from '../SpecialConditionPanel'
import { OptionGroupProps } from '..'

const ModalSelect: React.FunctionComponent<{
  optionsGroups: OptionGroupProps[]
  onClick?: () => void
}> = ({ optionsGroups, onClick }) => {
  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const showModal = () => setOpenModal(true)
  const closeModal = () => setOpenModal(false)

  return (
    <div className="w-full" onClick={onClick}>
      <div
        className="text-primary underline mb-8 text-sm lg:text-base"
        onClick={showModal}
      >
        {t('Other conditions')}
      </div>
      <Modal
        position="bottom"
        open={openModal}
        onClose={closeModal}
        containerClassName="!pt-0 !px-0 !pb-0 !w-full !h-full"
      >
        <div className="flex flex-col bg-white shadow-xl transform transition-all w-full h-3/4 rounded-t-2.5xl">
          <div className="px-6 py-6 text-center">
            <ModalHeader
              label={t('Special conditions')}
              onClose={closeModal}
              labelClassName="!text-2xl"
            />
          </div>
          <div className="flex flex-col flex-1 h-full overflow-y-auto md:border-t border-gray-800 text-left">
            <SpecialConditionPanel optionsGroups={optionsGroups} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalSelect
