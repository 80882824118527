'use client'

import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import DefaultButton from './DefaultButton'
import CheckIcon from 'public/icons/check.svg'
import SelectProps from './Select.props'

const Select: React.FunctionComponent<SelectProps> = ({
  name,
  label,
  placeHolder,
  disabled,
  isError,
  loading,
  errorMessage,
  options,
  value,
  containerClassName,
  buttonClassName,
  optionListClassName,
  SelectButton = DefaultButton,
  hideDefaultOptions,
  onChanged,
  onClick,
}) => {
  const selectedItem = options?.find((x) => x.value === value)

  const onSelect = (value: any) => {
    value !== null &&
      value !== undefined &&
      value !== '' &&
      onChanged &&
      onChanged(value)
  }

  const groupOptions = options.reduce(
    (result, option) => {
      const groupName = option.groupName || 'DEFAULT'
      const group = result.find((item) => item.groupName === groupName)
      if (!group) {
        return [
          ...result,
          {
            groupName: groupName as string,
            options: [option],
          },
        ]
      }

      return [
        ...result.filter((item) => item.groupName !== groupName),
        {
          groupName: groupName as string,
          options: [...group.options, option],
        },
      ]
    },
    [] as {
      groupName: string
      options: {
        label: string
        value: any
      }[]
    }[],
  )

  return (
    <div className={containerClassName}>
      <Listbox
        disabled={disabled}
        value={selectedItem?.value}
        onChange={onSelect}
      >
        {({ open }) => (
          <>
            <div className="relative">
              <SelectButton
                name={name}
                open={open}
                isError={isError}
                placeHolder={placeHolder}
                title={selectedItem?.label || placeHolder}
                loading={loading}
                label={label}
                disabled={disabled}
                value={value}
                options={options}
                onSelect={onSelect}
                className={buttonClassName}
                onClick={onClick}
                hideDefaultOptions={hideDefaultOptions}
              />
              <div className="hidden md:block">
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={classNames(
                      'absolute bg-white shadow-lg px-4 z-10 mt-1 w-full max-h-122 text-base overflow-auto focus:outline-none space-y-8',
                      optionListClassName,
                      groupOptions.length > 1 ? 'py-6' : 'py-4',
                    )}
                  >
                    {groupOptions.map((groupOption) => (
                      <div key={groupOption?.groupName}>
                        {groupOptions.length > 1 && (
                          <div className="mb-4 pl-3 text-gray-light font-lora text-lg whitespace-nowrap">
                            {groupOption.groupName}
                          </div>
                        )}
                        <div className="">
                          {groupOption?.options?.map((option) => (
                            <Listbox.Option
                              key={option.value}
                              className={({ active }) =>
                                classNames(
                                  active && 'text-primary',
                                  'cursor-pointer select-none relative py-3 pl-3 pr-9',
                                )
                              }
                              value={option.value}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate',
                                    )}
                                  >
                                    {option.label}
                                  </span>

                                  {selected && (
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                      <CheckIcon className="stroke-current text-primary" />
                                    </span>
                                  )}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </div>
            {isError && errorMessage && (
              <p className="mt-2 text-xs text-error">{errorMessage}</p>
            )}
          </>
        )}
      </Listbox>
    </div>
  )
}

export default Select
