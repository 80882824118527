'use client'

import { useState } from 'react'
import { useTranslation } from 'app/i18n/client'

import useAvaialbleCalendar from './useAvailableCalendar'

import toJapanTimeZone from 'utils/toJapanTimeZone'

import dayjs from 'lib/dayjs'

import ModalPicker from './ModalPicker'
import PopupPicker from './PopupPicker'
import MaxNightModal from './MaxNightModal'
import { useParams } from 'next/navigation'

export interface DateRangePickerProps {
  value?: (Date | null)[]
  label?: string
  disabled?: boolean
  onChange?: (value: (Date | null)[]) => void
  onClick?: () => void
  containerClassNames?: string
  buttonContainerClassNames?: string
  buttonClassNames?: string
  isError?: boolean
  errorMessage?: string
  roomGuests?: {
    adults: number
    children: number
  }[]
  hotelId?: number
}

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = ({
  value = [null, null],
  label,
  onChange,
  onClick,
  containerClassNames,
  buttonContainerClassNames,
  buttonClassNames,
  isError,
  errorMessage,
  roomGuests,
  hotelId,
  disabled,
}) => {
  const { t } = useTranslation()

  const [currentActiveStartDate, setCurrentActiveStartDate] = useState(
    new Date(toJapanTimeZone(dayjs()).format('YYYY-MM-DDTHH:mm:ss')),
  )
  const [showMaxNightModal, setShowMaxNightModal] = useState(false)

  const { disabledDates, isLoading } = useAvaialbleCalendar(
    currentActiveStartDate,
    roomGuests || [
      {
        adults: 1,
        children: 0,
      },
    ],
    hotelId,
  )

  const [fromValue, toValue] = value

  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const formatDate = (date?: Date | null, locale?: string) => {
    if (!date || !locale) return t('Select Date')

    return dayjs(date)
      .locale(['zh-Hans', 'zh-Hant'].includes(locale) ? 'zh-cn' : locale)
      .format('ll')
  }

  const nightCount =
    !fromValue || !toValue ? 0 : Math.abs(dayjs(fromValue).diff(toValue, 'day'))

  return (
    <>
      <div className="md:hidden">
        <ModalPicker
          label={label}
          containerClassNames={containerClassNames}
          buttonClassNames={buttonClassNames}
          buttonContainerClassNames={buttonContainerClassNames}
          isError={isError}
          checkIn={formatDate(fromValue, locale)}
          checkOut={formatDate(toValue, locale)}
          currentActiveStartDate={currentActiveStartDate}
          locale={locale}
          disabledDates={!!hotelId ? disabledDates : undefined}
          isLoading={isLoading}
          value={value}
          onChange={onChange}
          setCurrentActiveStartDate={setCurrentActiveStartDate}
          onClick={onClick}
          disabled={disabled}
          onOpenMaxNightModal={() => setShowMaxNightModal(true)}
        />
      </div>
      <div className="hidden md:block">
        <PopupPicker
          label={label}
          containerClassNames={containerClassNames}
          buttonClassNames={buttonClassNames}
          buttonContainerClassNames={buttonContainerClassNames}
          isError={isError}
          checkIn={formatDate(fromValue, locale)}
          checkOut={formatDate(toValue, locale)}
          selectedCheckIn={fromValue ? formatDate(fromValue, locale) : ''}
          selectedCheckOut={toValue ? formatDate(toValue, locale) : ''}
          nightCount={nightCount}
          currentActiveStartDate={currentActiveStartDate}
          locale={locale}
          selectedValue={value}
          disabledDates={!!hotelId ? disabledDates : undefined}
          onSelectValue={onChange}
          setCurrentActiveStartDate={setCurrentActiveStartDate}
          isLoading={isLoading}
          onClick={onClick}
          disabled={disabled}
          onOpenMaxNightModal={() => setShowMaxNightModal(true)}
        />
      </div>
      {isError && errorMessage && (
        <p className="mt-2 text-error text-xs">{errorMessage}</p>
      )}
      <MaxNightModal
        open={showMaxNightModal}
        onClose={() => setShowMaxNightModal(false)}
      />
    </>
  )
}

export default DateRangePicker
