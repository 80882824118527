import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import dayjs from 'lib/dayjs'
import { some } from 'lodash'
import { useTranslation } from 'app/i18n/client'
import ArrowLeft from 'public/icons/arrow-left.svg'
import ArrowRight from 'public/icons/arrow-right.svg'
import { MutableRefObject } from 'react'
import { toast } from 'react-toastify'
import { formatDate } from 'utils'
import { MAX_NIGHS_RESERVATION } from 'utils/constants'
import { setDateStyles } from '../utils'
import { useParams } from 'next/navigation'

interface DateRageSelectProps {
  calendarRef: MutableRefObject<undefined>
  currentActiveStartDate: Date
  selectedValue: (Date | null)[]
  today: Date
  disabledDates?: Date[]
  showDoubleView?: boolean
  onSelectValue?: (value: (Date | null)[]) => void
  handleCancel: () => void
  setCurrentActiveStartDate: (value: Date) => void
  onClose?: () => void
  onOpenMaxNightModal: () => void
}

const DateRangeSelect: React.FC<DateRageSelectProps> = ({
  calendarRef,
  showDoubleView,
  currentActiveStartDate,
  selectedValue,
  today,
  disabledDates,
  onSelectValue,
  handleCancel,
  setCurrentActiveStartDate,
  onClose,
  onOpenMaxNightModal,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}
  const [fromValue, toValue] = selectedValue || []
  const firstDisabledDateAfterFromDate =
    !!fromValue && !toValue
      ? disabledDates?.filter((date) =>
          dayjs(date).isAfter(dayjs(fromValue)),
        )?.[0]
      : undefined

  const disabledValues = disabledDates?.filter(
    (date) => date !== firstDisabledDateAfterFromDate,
  )

  return (
    <DateRangePicker
      inputRef={calendarRef as React.Ref<any>}
      showDoubleView={showDoubleView}
      activeStartDate={currentActiveStartDate}
      value={selectedValue as [Date | null, Date | null]}
      locale={locale}
      calendarType="US"
      nextLabel={<ArrowRight className="fill-current text-gray-dark" />}
      prevLabel={<ArrowLeft className="fill-current text-gray-dark" />}
      rangeDivider=""
      minDate={today}
      isOpen={true}
      formatDay={(_: string | undefined, date: Date) =>
        dayjs(date).get('date')?.toString()
      }
      onClickDay={(value: any) => {
        if (!onSelectValue) return

        const [startDate, endDate] = selectedValue

        if (startDate !== null && endDate === null) return

        onSelectValue([new Date(value.toDateString()), null])
      }}
      onChange={(newValue: any) => {
        const [startDate, endDate] = newValue

        const totalNights = Math.abs(
          dayjs(endDate).diff(dayjs(startDate), 'days'),
        )

        if (totalNights > MAX_NIGHS_RESERVATION) {
          onOpenMaxNightModal()
          handleCancel()
          return
        }

        if (
          disabledDates &&
          some(disabledDates, (disabledDate) =>
            dayjs(disabledDate).isBetween(
              dayjs(startDate),
              dayjs(endDate),
              'day',
              '()',
            ),
          )
        ) {
          toast.warn(t('Fully booked in the period'))
          handleCancel()
          return
        }
        onSelectValue &&
          onSelectValue([
            new Date(startDate.toDateString()),
            new Date(endDate.toDateString()),
          ])

        onClose && onClose()
      }}
      onActiveStartDateChange={({ action, activeStartDate }: any) => {
        if (action !== 'onChange') {
          setCurrentActiveStartDate(activeStartDate)
        }

        setTimeout(() => {
          setDateStyles(calendarRef, today)
        }, 0)
      }}
      tileDisabled={({ _, date }: any) =>
        !disabledValues
          ? false
          : disabledValues
              .map((disabledDate) => formatDate(disabledDate))
              .includes(formatDate(date))
      }
      tileClassName={({ _, date }: any) => {
        return dayjs(date).isSameOrAfter(today) &&
          disabledValues &&
          disabledValues
            .map((disabledDate) => formatDate(disabledDate))
            .includes(formatDate(date))
          ? '!line-through'
          : null
      }}
    />
  )
}

export default DateRangeSelect
