'use client'

import classNames from 'classnames'
import { sizeStyles, themeStyles, borderStyles } from './styles'
import { TextAreaProps } from './TextArea.props'

const Input: React.FunctionComponent<TextAreaProps> = ({
  label,
  inputSize = 'default',
  theme = 'light',
  placeHolder,
  isError = false,
  errorMessage,
  containerClassName,
  ...rest
}) => {
  return (
    <div className={containerClassName}>
      {label && (
        <div className="block text-xs text-gray-dark mb-3 uppercase">
          {label}
        </div>
      )}
      <div>
        <textarea
          rows={3}
          className={classNames(
            'block w-full focus:outline-none resize-none',
            [sizeStyles[inputSize]],
            [themeStyles[theme]],
            {
              [borderStyles.error]: isError,
              [borderStyles[theme]]: !isError,
            },
          )}
          placeholder={placeHolder}
          {...rest}
        />
      </div>
      {isError && errorMessage && (
        <p className="mt-2 text-error text-xs">{errorMessage}</p>
      )}
    </div>
  )
}

export default Input
