import { pickBy, identity } from 'lodash'
import { RoomPriceInfo } from 'tokyu-hooks/lib/request/hotel'

const parseRooms = ({
  rooms,
  noDiscount = false,
}: {
  rooms: RoomPriceInfo[]
  noDiscount?: boolean
}) => {
  return rooms?.map((room) => {
    return pickBy(
      {
        membership_promotion_id: noDiscount
          ? null
          : room.membership_promotion_id,
        number_of_adult: Number(room.number_of_adult),
        number_of_children: Number(room.number_of_children),
        number_of_room: Number(room.number_of_room),
        room_plan_code: room.room_plan_code,
      },
      identity,
    )
  })
}

export default parseRooms
