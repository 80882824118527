import { jwtDecode } from 'jwt-decode'
import dayjs from 'lib/dayjs'

export const isTokenExpired = (token: string) => {
  if (!token) {
    return true
  }

  const decoded: any = jwtDecode(token)

  return (
    !decoded ||
    !decoded?.datetime ||
    dayjs(decoded?.datetime * 1000)
      .add(23, 'hour')
      .isBefore(dayjs(new Date()))
  )
}
