import dayjs from 'lib/dayjs'

const toJapanTimeZone = (date: string | Date | dayjs.Dayjs) =>
  dayjs.tz(date, 'Japan')

export const toJapanTimeZoneEndDate = (date: string | Date | dayjs.Dayjs) =>
  toJapanTimeZone(date).set('hour', 23).set('minute', 59).set('second', 59)

export const getJapanTimeZoneToday = () =>
  dayjs(toJapanTimeZone(dayjs()).format('YYYY-MM-DD'))

export default toJapanTimeZone
