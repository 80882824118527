import React from 'react'
import classNames from 'classnames'

import Image from 'next/image'

import ArrowLeftCircleIcon from 'public/icons/chevron-left-circle.svg'
import ArrowRightCircleIcon from 'public/icons/chevron-right-circle.svg'

interface ThumbViewProps {
  images: string[]
  selectedIndex: number
  viewportRefType: <ViewportElement extends HTMLElement>(
    instance: ViewportElement | null,
  ) => void
  onSelect: (index: number) => void
  heighClassNames?: string
  widthClassNames?: string
  containerClassNames?: string
  backClassNames?: string
  nextClassNames?: string
}

const ThumbView: React.FunctionComponent<ThumbViewProps> = ({
  viewportRefType,
  images,
  selectedIndex,
  onSelect,
  heighClassNames = 'h-19',
  widthClassNames = 'w-19',
  containerClassNames,
  backClassNames = 'pl-3 lg:pl-0 pr-3',
  nextClassNames = 'pr-3 lg:pr-0 pl-3',
}) => {
  const canGoPrev = selectedIndex > 0
  const canGoNext = selectedIndex < images.length - 1

  const onNext = () => canGoNext && onSelect(selectedIndex + 1)
  const onPrev = () => canGoPrev && onSelect(selectedIndex - 1)

  return (
    <div className="relative mt-5.5 flex items-center">
      <div
        className={classNames(
          'flex justify-center items-center',
          heighClassNames,
          backClassNames,
          canGoPrev ? 'cursor-pointer' : 'cursor-default',
        )}
        onClick={() => canGoPrev && onPrev()}
      >
        <ArrowLeftCircleIcon
          className={classNames(
            'stroke-current',
            canGoPrev ? 'text-primary' : 'text-gray-50',
          )}
        />
      </div>
      <div className="w-full overflow-hidden" ref={viewportRefType}>
        <div
          className={classNames(
            'flex cursor-default space-x-4',
            containerClassNames,
          )}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={classNames(
                'relative',
                index === selectedIndex
                  ? 'border-2 border-primary'
                  : 'opacity-50',
              )}
            >
              <div className={widthClassNames}>
                <div
                  onClick={() => onSelect(index)}
                  className={classNames(
                    `cursor-pointer relative overflow-hidden w-full`,
                    heighClassNames,
                  )}
                >
                  <Image
                    alt=""
                    src={image}
                    priority
                    fill
                    sizes="100vw"
                    style={{
                      objectFit: 'cover',
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          'flex justify-center items-center',
          heighClassNames,
          nextClassNames,
          canGoNext ? 'cursor-pointer' : 'cursor-default',
        )}
        onClick={() => canGoNext && onNext()}
      >
        <ArrowRightCircleIcon
          className={classNames(
            'stroke-current',
            canGoNext ? 'text-primary' : 'text-gray-50',
          )}
        />
      </div>
    </div>
  )
}

export default ThumbView
