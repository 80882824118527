import {
  IGetAvailabilityCalendarResponse,
  IGetRoomsPriceResponse,
  IGetSearchBarSettingsResponse,
  IListPlansResponse,
  ISearchAvailabilityResponse,
} from 'tokyu-hooks/lib/response/hotel'
import {
  AdditionalQuestionModel,
  AvailabilityCalendarModel,
  PlanListItemModel,
  RoomPriceModel,
  RoomSelectInfo,
  SearchResultModel,
  SearchSettingModel,
} from './types'
import { IAdditionalQuestionsResponse } from 'tokyu-hooks/lib/response/reservation'
import every from 'lodash/every'

type PlanListItemData = IListPlansResponse['data'][0]
type SearchResponseData = ISearchAvailabilityResponse['data']
type SearchBarSetting = IGetSearchBarSettingsResponse['data']
type RoomPriceData = IGetRoomsPriceResponse['data']
type AdditionalQuestionData = IAdditionalQuestionsResponse['data'][0]
type AvailabilityCalendaData = IGetAvailabilityCalendarResponse['data']

export const DEFAULT_SEARCH_RESULT_SUBITEMS_NUMBER = 2

export const TOP_HOTEL_SEARCH_RECOMMENDED = 3

export const AREA_OPTION_ALL = 'ALL_AREA'

export const HOTEL_OPTION_ALL = 'ALL'

export const HOTEL_SEARCH_ORDER_BY = {
  PRICE_LOW_TO_HIGH: 'price_low_to_high',
  PRICE_HIGH_TO_LOW: 'price_high_to_low',
  RECOMMENDED: 'recommended',
}

export const HOTEL_SEARCH_ORDER_BY_DEFAULT = HOTEL_SEARCH_ORDER_BY.RECOMMENDED

export const toPlanListItem = (plan: PlanListItemData): PlanListItemModel => ({
  id: plan.id,
  hotelId: plan.hotel_id,
  name: plan.name,
  code: plan.code,
  description: plan.description,
  minStay: plan.min_stay,
})

export const toSearchResultModel = (
  searchResull?: SearchResponseData,
): SearchResultModel | undefined => {
  if (!searchResull) return searchResull

  return {
    plans: searchResull?.plans?.map((plan) => ({
      code: plan.code,
      name: plan.name,
      description: plan.description,
      urlPlanPictures: plan.url_plan_pictures,
      rooms: plan?.rooms?.map((room) => ({
        roomPlanCode: room.room_plan_code,
        matchedSearchCriteria: {
          adultsCount: room?.matched_search_criteria?.adults_count,
          childrenCount: room?.matched_search_criteria?.children_count,
        },
        roomTypeCode: room.room_type_code,
        hotelPlanCode: room.hotel_plan_code,
        roomTypeName: room.room_type_name,
        filters: room.filters?.map((filter) => ({
          id: filter.id,
          name: filter.name,
        })),
        signInDiscount: {
          totalPriceAfterDiscount:
            room?.sign_in_discount?.total_price_after_discount,
          totalPriceAfterDiscountTax:
            room?.sign_in_discount?.total_price_after_discount_tax,
          promotions: room?.sign_in_discount?.promotions?.map((promotion) => ({
            id: promotion.id,
            name: promotion.name,
            promotionType: promotion.promotion_type,
          })),
        },
        minPeople: room.min_people,
        maxPeople: room.max_people,
        maxAdult: room.max_adult,
        totalPrice: room.total_price,
        tax: room.tax,
        roomCount: room.room_count,
        roomPictures: room.room_pictures.flatMap(
          (picture) => picture?.picture?.url,
        ),
      })),
    })),
    rooms: searchResull?.rooms?.map((room) => ({
      roomTypeCode: room.room_type_code,
      roomTypeName: room.room_type_name,
      filters: room.filters?.map((filter) => ({
        id: filter.id,
        name: filter.name,
      })),
      roomPictures: room.room_pictures.flatMap(
        (picture) => picture?.picture?.url,
      ),
      roomCount: room.room_count,
      plans: room?.room_plan?.map((plan) => ({
        matchedSearchCriteria: {
          adultsCount: plan?.matched_search_criteria?.adults_count,
          childrenCount: plan?.matched_search_criteria?.children_count,
        },
        roomPlanCode: plan.room_plan_code,
        roomTypeCode: plan.room_type_code,
        hotelPlanCode: plan.hotel_plan_code,
        roomPlanName: plan.room_plan_name,
        signInDiscount: {
          totalPriceAfterDiscount:
            plan?.sign_in_discount?.total_price_after_discount,
          totalPriceAfterDiscountTax:
            plan?.sign_in_discount?.total_price_after_discount_tax,
          promotions: plan?.sign_in_discount?.promotions?.map((promotion) => ({
            id: promotion.id,
            name: promotion.name,
            promotionType: promotion.promotion_type,
          })),
        },
        totalPrice: plan.total_price,
        tax: plan.tax,
        urlPlanPictures: plan.url_plan_pictures,
      })),
    })),
  }
}

export const getRoomOptions = (maxRooms: number) => {
  return Array(Math.min(maxRooms, 4))
    .fill(0)
    .map((_, index: number) => ({
      label: (index + 1).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      value: index + 1,
    }))
}

export const toSearchBarSettings = (
  searchBarSettingsData: SearchBarSetting,
): SearchSettingModel => ({
  checkinDateOffset:
    searchBarSettingsData?.search_bar_setting_attributes?.default_search_setting
      ?.checkin_date_offset,
})

export const groupRoomSelectList: (
  roomList: RoomSelectInfo[],
) => RoomSelectInfo[] = (roomList) =>
  roomList.reduce((result: RoomSelectInfo[], item: RoomSelectInfo) => {
    const condition: (r: RoomSelectInfo) => boolean = (r) =>
      r.roomPlanCode === item.roomPlanCode &&
      r.numberOfAdult.toString() === item.numberOfAdult.toString() &&
      r.numberOfChildren.toString() === item.numberOfChildren.toString()

    const currentItem = result.find(condition)

    return currentItem
      ? [
          ...result.filter((room) => !condition(room)),
          {
            ...currentItem,
            number_of_room: currentItem.numberOfRoom + item.numberOfRoom,
          },
        ]
      : [...result, item]
  }, [] as RoomSelectInfo[])

export const toRoomPiceModel = (
  roomPriceData: RoomPriceData,
): RoomPriceModel => ({
  totalPrice: roomPriceData.total_price,
  couponDiscountAmount: roomPriceData.coupon_discount_amount,
  bonusPoint: roomPriceData.bonus_point,
  spendingPointValue: roomPriceData.spending_point_value,
  originalPriceWithTax: roomPriceData.original_price_with_tax,
  rooms: roomPriceData.rooms.map((room) => ({
    roomPlanCode: room.room_plan_code,
    roomTypeCode: room.room_type_code,
    adults: room.adults,
    children: room.children,
    totalPrice: room.total_price,
    tax: room.tax,
    couponDiscountAmount: room.coupon_discount_amount,
    rateDetails: room.rate_details,
    roomTypeName: room.room_type_name,
    roomPlanName: room.room_plan_name,
    roomPictures: room.room_pictures.flatMap(
      (picture) => picture?.picture?.url,
    ),
    membershipPromotionId: room.membership_promotion_id,
  })),
})

export const toAdditionalQuestionModel = (
  additionalQuestion: AdditionalQuestionData,
): AdditionalQuestionModel => ({
  id: additionalQuestion?.id,
  question: additionalQuestion?.question,
  questionType: additionalQuestion?.question_type,
  answerType: additionalQuestion?.answer_type,
  settings: {
    startedAt: additionalQuestion?.settings?.started_at,
    endedAt: additionalQuestion?.settings?.ended_at,
    timeIntervalValue: additionalQuestion?.settings?.time_interval_value,
    timeIntervalUnit: additionalQuestion?.settings?.time_interval_unit,
    answerOptions: {
      en: additionalQuestion?.settings?.answer_options?.en?.map((item) => ({
        text: item.text,
      })),
      ja: additionalQuestion?.settings?.answer_options?.ja?.map((item) => ({
        text: item.text,
      })),
      ko: additionalQuestion?.settings?.answer_options?.ko?.map((item) => ({
        text: item.text,
      })),
      zh_Hans: additionalQuestion?.settings?.answer_options?.zh_Hans?.map(
        (item) => ({
          text: item.text,
        }),
      ),
      zh_Hant: additionalQuestion?.settings?.answer_options?.zh_Hant?.map(
        (item) => ({
          text: item.text,
        }),
      ),
    },
  },
  hotelPlanCodes: additionalQuestion?.hotel_plan_codes?.map((item) => item),
})

export const toAvailabilityCalendarModel = (
  availabilityCalendar: AvailabilityCalendaData,
): AvailabilityCalendarModel => ({
  id: availabilityCalendar?.id,
  disabledDates: availabilityCalendar?.disabled_dates?.map((item) => item),
})

export const getPlanImagesByLocale = (images?: string[], locale?: string) => {
  if (!images) return undefined

  return images?.filter(
    (picture) =>
      picture.includes(`_${locale}.`) ||
      every(
        ['ja', 'en', 'ko', 'zh-Hans', 'zh-Hant'],
        (lo) => !picture.includes(`_${lo}.`),
      ),
  )
}
