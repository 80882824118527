import classNames from 'classnames'
import { useTranslation } from 'app/i18n/client'

import ArrowDownIcon from 'public/icons/arrow-down.svg'
import ArrowUpIcon from 'public/icons/arrow-up.svg'

import type { ReactElement } from 'react'

export interface ButtonProps {
  title: string
  open: boolean
  containerClassName?: string
  labelClassName?: string
  buttonClassName?: string
  Icon?: ReactElement
  isError?: boolean
  errorMessage?: string
}

const Button: React.FunctionComponent<ButtonProps> = ({
  title,
  open,
  containerClassName,
  labelClassName,
  buttonClassName,
  Icon,
  isError,
  errorMessage,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames('w-full bg-transparent', containerClassName)}>
      <label
        className={classNames(
          'flex flex-start text-xxs lg:text-xs text-gray-light uppercase text-left',
          labelClassName,
        )}
      >
        {t('Rooms & Guests')}
      </label>
      <div
        className={classNames(
          'flex items-center justify-between mt-2 lg:mt-3 border border-gray-150 px-4 py-3 text-lg bg-transparent',
          buttonClassName,
          {
            '!border !border-error': isError,
          },
        )}
      >
        {title}
        {Icon ? (
          Icon
        ) : (
          <>
            <ArrowDownIcon
              className={classNames(
                'ml-3 fill-current text-gray-500 lg:mr-2',
                open ? 'lg:hidden' : 'hidden lg:block',
              )}
            />
            <ArrowUpIcon
              className={classNames(
                'ml-3 fill-current text-yellow-50 lg:mr-2',
                open ? 'hidden lg:block' : 'lg:hidden',
              )}
            />
          </>
        )}
      </div>
      {isError && errorMessage && (
        <p className="flex mt-2 text-error text-xs">{errorMessage}</p>
      )}
    </div>
  )
}

export default Button
