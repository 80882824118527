import { useTranslation } from 'app/i18n/client'
import { Modal } from 'app/components/atoms'
import CrossIcon from 'public/icons/cross.svg'
import { INQUIRY_LONG_STAY_LINK } from 'utils/constants'

interface MaxNightModalProps {
  open: boolean
  onClose: () => void
}

const MaxNightModal: React.FunctionComponent<MaxNightModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose}>
      <div className="inline-block bg-white shadow-xl transform transition-all w-96">
        <div className="pt-12 p-8 text-left border-t border-gray-800">
          <div
            className="absolute top-6 right-3 cursor-pointer"
            onClick={onClose}
          >
            <CrossIcon className="w-6 h-6 text-gray-dark fill-current" />
          </div>
          <p className="text-base leading-7 text-left space-x-1">
            {t(
              'The maximum number of consecutive nights in a single booking is 31. If you wish to stay for a minimum of 32 nights, please contact us using the contact form.',
            )}
          </p>
          <div className="mt-6 flex items-center w-full justify-center">
            <div className="flex-none">
              <a
                href={INQUIRY_LONG_STAY_LINK}
                target="blank"
                className="inline-flex items-center justify-center text-white bg-primary focus:outline-none px-8 py-4 text-base font-semibold bg-primary w-full !flex-none space-x-1"
              >
                {t('Click here for inquiries')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MaxNightModal
