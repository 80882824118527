'use client'

import classNames from 'classnames'

import MinusCircleIcon from 'public/icons/minus-circle.svg'
import PlusCircleIcon from 'public/icons/plus-circle.svg'

export interface NumberSelectProps {
  value: number
  maxValue?: number
  minValue?: number
  onIncreased: () => void
  onDecreased: () => void
}

const NumberSelect: React.FunctionComponent<NumberSelectProps> = ({
  value,
  maxValue,
  minValue = 0,
  onIncreased,
  onDecreased,
}) => {
  const canIncrease =
    maxValue === undefined || maxValue === null || value < maxValue
  const canDecrease = value > minValue

  return (
    <div className="flex items-center justify-between w-lg">
      <MinusCircleIcon
        className={classNames(
          'cursor-pointer stroke-current',
          canDecrease ? 'text-primary' : 'text-gray-50',
        )}
        onClick={() => canDecrease && onDecreased()}
      />
      <div className="text-gray-dark text-xl lg:text-2xl select-none">
        {value}
      </div>
      <PlusCircleIcon
        className={classNames(
          'cursor-pointer stroke-current',
          canIncrease ? 'text-primary' : 'text-gray-50',
        )}
        onClick={() => canIncrease && onIncreased()}
      />
    </div>
  )
}

export default NumberSelect
