import classNames from 'classnames'
import { Listbox } from '@headlessui/react'

import { Loading } from 'app/components/atoms'

import { borderStyles } from './styles'

import ArrowDownIcon from 'public/icons/arrow-down.svg'
import ArrowUpIcon from 'public/icons/arrow-up.svg'

import SelectButtonProps from './SelectButton.props'

import { useTranslation } from 'app/i18n/client'

const DefaultButton: React.FunctionComponent<SelectButtonProps> = ({
  name,
  isError,
  open,
  title,
  label,
  loading,
  disabled,
  value,
  options,
  className,
  onSelect,
  hideDefaultOptions,
}) => {
  const { t } = useTranslation()

  return (
    <div className="relative">
      {label && (
        <Listbox.Label className="block text-xs text-secondary mb-3 uppercase">
          {label}
        </Listbox.Label>
      )}
      <div className="relative">
        <Listbox.Button
          name={name}
          className={classNames(
            'block w-full focus:outline-none py-3.5 px-4 text-base disabled:bg-gray-100 flex justify-between',
            isError ? borderStyles.error : borderStyles.default,
            isError ? 'bg-[#E74C3C] bg-opacity-15' : 'bg-[#FAFAFA]',
            className,
            {
              '!opacity-40': loading || disabled,
            },
          )}
        >
          {loading ? (
            <div className="flex-1 flex items-center justify-center py-3 px-4">
              <Loading className="!text-white" baseClassName="!text-priority" />
            </div>
          ) : (
            <>
              <span className="hidden md:block truncate">{title}</span>
              <select
                disabled={disabled}
                value={value}
                className="w-full block md:hidden truncate focus:outline-none bg-transparent"
                onChange={(event) =>
                  event.target.value && onSelect(event.target.value)
                }
              >
                {!hideDefaultOptions && (
                  <option value="">{t('Select one')}</option>
                )}
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </>
          )}
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ArrowDownIcon
              className={classNames(
                'fill-current text-primary',
                open ? 'block lg:hidden' : 'block',
              )}
            />
            <ArrowUpIcon
              className={classNames(
                'fill-current text-primary',
                open ? 'hidden lg:block' : 'hidden',
              )}
            />
          </div>
        </Listbox.Button>
      </div>
    </div>
  )
}

export default DefaultButton
