'use client'

import React, { useState, useEffect, useCallback, ReactNode } from 'react'
import classNames from 'classnames'

import useEmblaCarousel from 'embla-carousel-react'

import ArrowLeft from 'public/icons/arrow-left-lg.svg'

interface CarouselProps {
  data: any[]
  renderItem: (item: any, index: number) => ReactNode
}

const Carousel: React.FunctionComponent<CarouselProps> = ({
  data,
  renderItem,
}) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: 'start',
    skipSnaps: false,
    dragFree: true,
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    embla.on('select', onSelect)
    onSelect()
  }, [embla, onSelect])

  return (
    <div className="lg:max-w-344 mx-auto w-full overflow-hidden relative bg-transparent">
      <div className="mx-8 lg:mx-20" ref={viewportRef}>
        <div className="flex space-x-8">
          {data.map((item, index) => renderItem(item, index))}
        </div>
        <button
          className="z-10 h-full w-8 lg:w-20 from-gray-dark absolute top-0 left-0 flex justify-center items-center"
          onClick={scrollPrev}
          disabled={!prevBtnEnabled}
        >
          <ArrowLeft className="fill-current text-gray-dark" />
        </button>
        <button
          className="z-10 h-full w-8 lg:w-20 absolute top-0 right-0 flex justify-center items-center"
          onClick={scrollNext}
          disabled={!nextBtnEnabled}
        >
          <ArrowLeft
            className={classNames('fill-current text-gray-dark', {
              'transform rotate-180': true,
            })}
          />
        </button>
      </div>
    </div>
  )
}

export default Carousel
