'use client'

import React from 'react'
import Checkbox from 'app/components/atoms/Checkbox'

import { useTranslation } from 'app/i18n/client'

import PopupSelect from './PopupSelect'
import ModalSelect from './ModalSelect'

import WashingMachineIcon from 'public/icons/washing-machine.svg'
import SpoonForkIcon from 'public/icons/spoon-fork.svg'

export interface OptionGroupProps {
  title: string
  Icon: React.ReactNode
  options?: {
    label: string
    value: string
    checked?: boolean
  }[]
  onCheck?: (name: string) => void
  disabled?: boolean
}

export interface AmenitiesSelectProps {
  planOptions?: {
    label: string
    value: string
    checked?: boolean
  }[]
  roomTypeOptions?: {
    label: string
    value: string
    checked?: boolean
  }[]
  onPlanCheck?: (name: string) => void
  onRoomTypeCheck?: (name: string) => void
  onClick?: () => void
}

const AmenitiesSelect: React.FunctionComponent<AmenitiesSelectProps> = ({
  planOptions,
  roomTypeOptions,
  onPlanCheck,
  onRoomTypeCheck,
  onClick,
}) => {
  const { t } = useTranslation()

  const defaultRoomTypeOptionSize = Math.min(3, roomTypeOptions?.length || 0)
  const defaulRoomTypeOptionOptions = roomTypeOptions?.slice(
    0,
    defaultRoomTypeOptionSize,
  )

  const disablePlanGroup = false
  const disableRoomTypeGroup = false

  const optionsGroups: OptionGroupProps[] = [
    {
      title: t('Filter by room'),
      Icon: (
        <WashingMachineIcon className="stroke-current text-gray-dark" />
      ) as React.ReactNode,
      options: roomTypeOptions,
      onCheck: onRoomTypeCheck,
      disabled: disableRoomTypeGroup,
    },
    {
      title: t('Filter by plan'),
      Icon: (
        <SpoonForkIcon className="fill-current text-gray-dark" />
      ) as React.ReactNode,
      options: planOptions,
      onCheck: onPlanCheck,
      disabled: disablePlanGroup,
    },
  ]

  const showSpecialPopup =
    (planOptions && planOptions.length > 0) ||
    (roomTypeOptions && roomTypeOptions.length > 0)

  return (
    <div>
      <label className="text-xs text-gray-light uppercase">
        {t('Amenities')}
      </label>
      <div className="grid grid-cols-2 gap-y-5 lg:flex lg:flex-start mt-4 lg:mt-2.5 lg:space-x-9.5">
        {defaulRoomTypeOptionOptions?.map((option) => (
          <Checkbox
            key={`amenity-${option.value}-${option.checked}`}
            disabled={disableRoomTypeGroup}
            label={option.label}
            name={`default-option-${option.value}`}
            checked={option.checked}
            onChange={() => {
              onRoomTypeCheck && onRoomTypeCheck(option.value)
              onClick && onClick()
            }}
            textClassName="text-sm lg:text-base"
            inputContainerClassName="!mt-0.5"
          />
        ))}
        {showSpecialPopup && (
          <div className="hidden lg:block">
            <PopupSelect optionsGroups={optionsGroups} onClick={onClick} />
          </div>
        )}
      </div>
      <div className="lg:hidden mt-6">
        <ModalSelect optionsGroups={optionsGroups} onClick={onClick} />
      </div>
    </div>
  )
}

export default AmenitiesSelect
