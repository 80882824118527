'use client'

import Link from 'next/link'

import classNames from 'classnames'

interface TabsProps {
  tabs: {
    name: string
    href: string
    active: boolean
  }[]
}

const Tabs: React.FunctionComponent<TabsProps> = ({ tabs }) => {
  return (
    <div className="mt-8 border-b border-gray-150">
      <nav className="flex space-x-8">
        {tabs.map((tab) => (
          <Link
            href={tab.href}
            key={tab.name}
            className={classNames(
              tab.active
                ? 'border-primary border-b-2 text-primary !font-semibold'
                : 'text-gray-light border-transparent hover:text-gray-dark hover:border-gray-light',
              'whitespace-nowrap py-2 px-2 border-b uppercase text-xs md:text-sm',
            )}
          >
            {tab.name}
          </Link>
        ))}
      </nav>
    </div>
  )
}

export default Tabs
