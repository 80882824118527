'use client'

import classNames from 'classnames'
import { alignStyles } from './styles'

import { ReactElement } from 'react'

export interface RadioGroupProps {
  label?: string | ReactElement
  options: Array<{
    label: string
    value: string
  }>
  value?: string
  isError?: boolean
  errorMessage?: string
  disabled?: boolean
  align?: 'vertical' | 'horizontal'
  onChanged?: (selected: string) => void
  onClick?: () => void
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  containerClassName?: string
  textClassName?: string
  optionContainerClassName?: string
}

const RadioGroup: React.FunctionComponent<RadioGroupProps> = ({
  label,
  align = 'vertical',
  value,
  options,
  disabled,
  isError,
  errorMessage,
  onChanged,
  onClick,
  inputProps,
  containerClassName,
  textClassName,
  optionContainerClassName,
}) => {
  return (
    <div>
      {label && <label className="block text-xs mb-3">{label}</label>}
      <fieldset>
        <div className={classNames(alignStyles[align], containerClassName)}>
          {options.map((option) => (
            <div
              key={`${option.value}-${value}`}
              className={`flex items-center ${optionContainerClassName}`}
            >
              <input
                id={option.value}
                name={option.value}
                type="radio"
                defaultChecked={option.value === value}
                className="h-5 w-5 opacity-0 absolute"
                disabled={disabled}
                onChange={() => {
                  onChanged && onChanged(option.value)
                  onClick && onClick()
                }}
                {...inputProps}
              />
              <div
                className={classNames(
                  'bg-white border-1.5 border-gray-50 rounded-full h-5 w-5 flex shrink-0 justify-center items-center mr-2',
                  isError
                    ? 'border-error'
                    : disabled
                      ? 'border-secondary'
                      : 'border-gray-light',
                )}
              >
                {option.value === value && (
                  <div
                    className={classNames(
                      'w-2 h-2 rounded-full',
                      disabled ? 'bg-secondary' : 'bg-primary',
                    )}
                  ></div>
                )}
              </div>
              <label
                htmlFor={option.value}
                className={classNames(
                  'uppercase text-sm lg:text-base',
                  disabled && 'text-gray-200',
                  textClassName,
                )}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
        {isError && errorMessage && (
          <p className="mt-2 text-error text-xs">{errorMessage}</p>
        )}
      </fieldset>
    </div>
  )
}

export default RadioGroup
