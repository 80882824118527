import { Hotel } from 'tokyu-hooks'
import { getClientSession } from 'lib/client/clientSession'
import { toAvailabilityCalendarModel } from 'core/bookings'

export const FETCH_AVAILABILITY_CALENDAR = 'FETCH_AVAILABILITY_CALENDAR'

export const fetchAvailabilityCalendar =
  (clientSession?: string) =>
  async ({ queryKey }: { queryKey: any[] }) => {
    const [_key, { year_month, nights, hotelId, rooms, locale }] = queryKey
    const res = await Hotel.getAvailabilityCalendar({
      baseUrl: process.env.NEXT_PUBLIC_TRIPLA_BASE_URL as string,
      clientSession: clientSession || (await getClientSession()),
      year_month: year_month as string,
      nights: nights,
      hotel_id: hotelId as string,
      rooms: rooms as {
        adults: number
        children: number
      }[],
      locale: locale as string,
    })

    return res?.data?.data
      ? toAvailabilityCalendarModel(res?.data?.data)
      : undefined
  }
