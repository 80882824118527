'use client'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'

import SpecialConditionPanel from '../SpecialConditionPanel'

import { useTranslation } from 'app/i18n/client'
import { OptionGroupProps } from '..'

const PopupSelect: React.FunctionComponent<{
  optionsGroups: OptionGroupProps[]
  onClick?: () => void
}> = ({ optionsGroups, onClick }) => {
  const { t } = useTranslation()

  return (
    <Popover className="relative w-full" onClick={onClick}>
      {() => {
        return (
          <>
            <Popover.Button className="w-full">
              <div className="text-primary underline">
                {t('Other conditions')}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 transform bg-white shadow-lg mt-1 w-104 max-h-85 3xl:max-h-125 text-base overflow-auto focus:outline-none">
                <SpecialConditionPanel optionsGroups={optionsGroups} />
              </Popover.Panel>
            </Transition>
          </>
        )
      }}
    </Popover>
  )
}

export default PopupSelect
