'use client'

import { useSearchParams } from 'next/navigation'
import { createContext, useEffect, useState } from 'react'

interface BaseUrlContextValue {
  baseUrl?: string
}

export const BaseUrlContext = createContext<BaseUrlContextValue>({
  baseUrl: undefined,
})

const BaseUrlContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const searchParams = useSearchParams()
  const [baseUrl, setBaseUrl] = useState('')

  const baseUrlQuery = searchParams?.get('baseUrl')

  useEffect(() => {
    if (baseUrlQuery) {
      setBaseUrl(baseUrlQuery as string)
    }
  }, [baseUrlQuery])

  return (
    <BaseUrlContext.Provider
      value={{
        baseUrl,
      }}
    >
      {children}
    </BaseUrlContext.Provider>
  )
}

export default BaseUrlContextProvider
