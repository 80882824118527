import Link from 'next/link'
import LogoIcon from 'public/logo.svg'
import LogoSmIcon from 'public/logo-sm.svg'
import { useContext } from 'react'
import { BaseUrlContext } from 'app/contexts/BaseUrlContext'

interface LogoProps {
  size?: 'default' | 'sm'
  icon?: React.ReactNode
}

const Logo: React.FunctionComponent<LogoProps> = ({
  size = 'default',
  icon,
}) => {
  const { baseUrl } = useContext(BaseUrlContext)
  return (
    <Link
      href={baseUrl || (process.env.NEXT_PUBLIC_TOP_PAGE_URL as string) || '/'}
      className="flex"
    >
      {icon || (size === 'default' ? <LogoIcon /> : <LogoSmIcon />)}
    </Link>
  )
}

export default Logo
