'use client'

import { Disclosure, Transition } from '@headlessui/react'
import classNames from 'classnames'

import ArrowDownIcon from 'public/icons/arrow-down.svg'

interface AccordionProps {
  title: string | React.ReactNode
  children: React.ReactNode
}

const Accordion: React.FunctionComponent<AccordionProps> = ({
  title,
  children,
}) => {
  return (
    <Disclosure defaultOpen>
      {({ open }: { open: boolean }) => (
        <>
          <Disclosure.Button className="flex items-center bg-[#FAFAFA] p-5 w-full text-left">
            <div className="w-8 pb-1">
              <ArrowDownIcon
                className={classNames({
                  'fill-current text-primary transform rotate-180': open,
                })}
              />
            </div>
            <div className="flex-1 text-primary">{title}</div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-in-out"
            enterFrom="transform scale-y-0 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-75 ease-in-out"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-0 opacity-0"
          >
            <Disclosure.Panel static className="bg-[#FAFAFA] p-5 pt-0">
              <div className="ml-8 break-words lg:break-normal">{children}</div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

export default Accordion
