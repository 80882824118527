import { useEffect, useRef } from 'react'

import dayjs from 'lib/dayjs'

import { useTranslation } from 'app/i18n/client'

import Button from '../../../Button'

import GuidelinePopup from '../../GuidelinePopup'
import { getJapanTimeZoneToday } from 'utils/toJapanTimeZone'
import { setDateStyles } from '../../utils'
import DateRangeSelect from '../../DateRangeSelect'
import Loading from 'app/components/atoms/Loading'
import { useParams } from 'next/navigation'

interface CalendarSelectProps {
  currentActiveStartDate: Date
  selectedValue: (Date | null)[]
  disabledDates?: Date[]
  isLoading?: boolean
  setCurrentActiveStartDate: (value: Date) => void
  handleReset: () => void
  handleCancel: () => void
  handleConfirm: () => void
  onSelectValue: (value: (Date | null)[]) => void
  onOpenMaxNightModal: () => void
}

const CalendarSelect: React.FC<CalendarSelectProps> = ({
  currentActiveStartDate,
  selectedValue,
  setCurrentActiveStartDate,
  isLoading,
  disabledDates,
  handleCancel,
  handleReset,
  handleConfirm,
  onSelectValue,
  onOpenMaxNightModal,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const [selectedFrom, selectedTo] = selectedValue

  const today = getJapanTimeZoneToday().toDate()

  const calendarRef = useRef()

  useEffect(() => {
    setDateStyles(calendarRef, today)
  }, [calendarRef?.current])

  const formatDate = (date?: Date | null, locale?: string) => {
    if (!date || !locale) return t('Select Date')

    return dayjs(date)
      .locale(['zh-Hans', 'zh-Hant'].includes(locale) ? 'zh-cn' : locale)
      .format('ll')
  }

  return (
    <div className="flex flex-col bg-white shadow-xl transform transition-all items-center w-full h-full rounded-t-2.5xl">
      <div className="px-8 w-full">
        <div className="mt-8 w-full flex justify-between py-3">
          <div>
            <label className="text-xs text-gray-50 uppercase">
              {t('Check-in Date')}
            </label>
            <div className="mt-1 font-lora text-xl">
              {formatDate(selectedFrom, locale)}
            </div>
          </div>
          <div>
            <label className="text-xs text-gray-50 uppercase">
              {t('Check-out Date')}
            </label>
            <div className="mt-1 font-lora text-xl">
              {formatDate(selectedTo, locale)}
            </div>
          </div>
        </div>
        <div className="border-b border-secondary w-full"></div>
      </div>
      <div className="w-full h-full max-h-full overflow-y-auto md:border-t border-gray-800 text-left">
        <div className="w-full flex flex-col flex-1 items-center relative">
          {isLoading && (
            <div className="absolute top-0 left-0 w-full h-full bg-[#F6F6F6] bg-opacity-80 z-[500] flex items-center justify-center">
              <Loading
                className="!text-[#F6F6F6] text-opacity-80"
                baseClassName="!text-primary"
              />
            </div>
          )}
          <div className="mt-4 w-76">
            <DateRangeSelect
              calendarRef={calendarRef}
              currentActiveStartDate={currentActiveStartDate}
              selectedValue={selectedValue}
              today={today}
              disabledDates={disabledDates}
              onSelectValue={onSelectValue}
              handleCancel={handleCancel}
              setCurrentActiveStartDate={setCurrentActiveStartDate}
              onOpenMaxNightModal={onOpenMaxNightModal}
            />
          </div>
        </div>
        <div className="px-8 flex-1 flex items-end w-full">
          <div className="py-6 flex justify-between space-x-6 w-full">
            <Button
              onClick={handleReset}
              variant="white"
              className="select-none uppercase !w-full !px-4"
            >
              {t('Close this modal')}
            </Button>
            <Button
              onClick={handleConfirm}
              className="select-none !bg-primary uppercase !w-full !px-4"
            >
              {t('Confirm')}
            </Button>
          </div>
        </div>
        <div className="pt-2 pb-4 flex items-center justify-center">
          <GuidelinePopup />
        </div>
      </div>
    </div>
  )
}

export default CalendarSelect
