'use client'

import React from 'react'
import Link from 'next/link'
import ArrowRightIcon from 'public/icons/arrow-right.svg'

interface BreakcrumbsProps {
  items: {
    name: string
    href?: string
    active?: boolean
  }[]
}

const Breakcrumbs: React.FunctionComponent<BreakcrumbsProps> = ({ items }) => {
  return (
    <nav className="mt-4 flex space-x-3 items-center text-sm">
      {items.map((item, i) => {
        return (
          <React.Fragment key={i}>
            {item.active ? (
              <div>{item.name}</div>
            ) : (
              <Link
                href={item.href as string}
                className="text-primary font-medium"
              >
                {item.name}
              </Link>
            )}
            {i < items.length - 1 && (
              <ArrowRightIcon className="text-gray-dark fill-current" />
            )}
          </React.Fragment>
        )
      })}
    </nav>
  )
}

export default Breakcrumbs
