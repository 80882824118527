import React from 'react'
import Image from 'next/image'

export enum CardTypeProps {
  'visa',
  'mastercard',
  'jcb',
  'amex',
  'dinnersclub',
}

export interface PaymentIconProps {
  cardType: CardTypeProps | string | null
  className?: string
}

const CARD_ICONS: { [key: string]: any } = {
  visa: '/images/cards/visa.png',
  mastercard: '/images/cards/master-card.png',
  jcb: '/images/cards/jcb.png',
  amex: '/images/cards/ac-card.png',
  dinersclub: '/images/cards/dinner-club.png',
}

const PaymentIcon = ({ cardType, className }: PaymentIconProps) => {
  if (!cardType || !CARD_ICONS?.[cardType]) {
    return null
  }
  return (
    <div className={`relative w-12.5 h-8 ${className}`}>
      <Image
        alt={cardType as string}
        src={CARD_ICONS[cardType]}
        priority
        fill
        sizes="100vw"
        style={{
          objectFit: 'cover',
        }}
      />
    </div>
  )
}

export default React.memo(PaymentIcon)
