export const RESERVATION_STATUS = {
  UPCOMING: 'upcoming',
  COMPLETED: 'done',
  CANCEL: 'cancelled',
}
export const ROOM_SIZE: { [key: string]: any } = {
  square_meters: 'm²',
  tatami_mats: 'tatami mats',
}

export const ROOM_GUEST_FIELD = 'roomGuests'

export const ADDITIONAL_QUESTIONS = {
  BOOK_FOR: 'Book for',
  BOOK_FOR_JP: '宿泊者情報',
  REMARK: 'Remark',
  REMARK_JP: '備考',
  ESTIMATED_ARRIVAL_TIME: 'check-in',
}

export const BOOKING_FOR_OPTIONS = {
  MY_SELF: 'My Self',
  SOMEONE_ELSE: 'Someone else',
}

export const CLIENT_SESSION_KEY = 'tripla_client_session'

export const JA_LANGUAGE = 'ja'
export const DEFAULT_LANG = JA_LANGUAGE

export const TERM_OF_USE = 'https://en.tripla.io/standard-travel-agency'
export const PRIVACY_POLICY = 'https://en.tripla.io/privacy-policy-2'
export const GDPR_POLICY = 'https://en.tripla.io/gdpr-privacy-policy/'
export const MEMBERSHIP_AGREEMENT = 'https://www.tokyustay.co.jp/clause/'
export const APP_TERMS_OF_USE = 'https://www.tokyustay.co.jp/clause/'
export const PRIVACY_POLICY_BY_THE_HOTEL =
  'https://www.tokyu-rs.co.jp/privacypolicy/'

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/tokyu-stay/id1613945562'
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.tokyuapp'

export const OPEN_APP_LINK = 'https://tokyuapp.page.link/open-app'

export const STAGING_FIREBASE_DYNAMIC_LINK =
  'https://tokyuapp.page.link/staging-app'

export const INQUIRY_LONG_STAY_LINK =
  'https://www.tokyustay.co.jp/inquiry_longstay'

export const DEVICE_NAMES = {
  ANDROID: 'Android',
  IOS: 'iOS',
  OTHERS: 'Others',
}

export const TEXT_HIRAGANA_REGEX = /^[ぁ-んー]+$/

export const MAX_NIGHS_RESERVATION = 31

export const DEFAULT_AMENITIES_NAMES = {
  en: ['Washer＆Dryer', 'Mini Kitchen', 'Microwave', 'Non-smoking', 'Smoking'],
  ja: ['洗濯乾燥機', 'ミニキッチン', '電子レンジ', '禁煙', '喫煙'],
  ko: ['세탁 건조기', '미니주방', '전자 레인지', '금연', '흡연'],
  'zh-Hans': ['洗衣烘衣机', '迷你厨房', '微波炉', '禁烟', '吸烟'],
  'zh-Hant': ['洗衣烘衣機', '迷你廚房', '微波爐', '禁菸', '吸菸'],
}
