import dayjs from 'lib/dayjs'

export const setDateStyles: (
  calendarRef: any,
  day: Date,
  classNames?: string,
) => void = (
  calendarRef,
  day,
  classNames = 'react-calendar__tile--current-date',
) =>
  calendarRef?.current
    ?.querySelectorAll(
      `.react-calendar__month-view .react-calendar__month-view__days button.react-calendar__tile abbr[aria-label='${dayjs(
        day,
      ).format('LL')}']`,
    )
    .forEach((node: any) => node?.parentNode?.classList.add(classNames))
