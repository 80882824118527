import { Fragment, useEffect, useRef } from 'react'
import { Popover, Transition } from '@headlessui/react'

import classNames from 'classnames'

import { useTranslation } from 'app/i18n/client'

import CalendarInIcon from 'public/icons/calendar-in.svg'
import CalendarOutIcon from 'public/icons/calendar-out.svg'

import GuidelinePopup from '../GuidelinePopup'
import { getJapanTimeZoneToday } from 'utils/toJapanTimeZone'

import { setDateStyles } from '../utils'
import Loading from 'app/components/atoms/Loading'
import DateRangeSelect from '../DateRangeSelect'

interface PopupPickerProps {
  selectedValue: (Date | null)[]
  label?: string
  containerClassNames?: string
  isError?: boolean
  buttonContainerClassNames?: string
  buttonClassNames?: string
  checkIn: string
  checkOut: string
  selectedCheckIn: string
  selectedCheckOut: string
  currentActiveStartDate: Date
  locale?: string
  nightCount: number
  disabledDates?: Date[]
  isLoading?: boolean
  disabled?: boolean
  onSelectValue?: (value: (Date | null)[]) => void
  setCurrentActiveStartDate: (value: Date) => void
  onClick?: () => void
  onOpenMaxNightModal: () => void
}

const PopupPicker: React.FunctionComponent<PopupPickerProps> = ({
  label,
  containerClassNames,
  buttonClassNames,
  buttonContainerClassNames,
  isError,
  checkIn,
  checkOut,
  selectedCheckIn,
  selectedCheckOut,
  currentActiveStartDate,
  selectedValue,
  nightCount,
  disabledDates,
  isLoading,
  disabled,
  onSelectValue,
  setCurrentActiveStartDate,
  onClick,
  onOpenMaxNightModal,
}) => {
  const today = getJapanTimeZoneToday().toDate()

  const calendarRef = useRef()

  const { t } = useTranslation()

  const handleCancel = () => onSelectValue && onSelectValue([null, null])

  const title =
    selectedCheckIn && selectedCheckOut !== null
      ? `${selectedCheckIn} - ${selectedCheckOut} • ${t('{{n}} night(s)', {
          n: nightCount,
        })}`
      : t('Please select date range')

  return (
    <div
      className={classNames('w-full', disabled && 'opacity-70')}
      onClick={onClick}
    >
      <Popover className="relative w-full">
        {({ open, close }: { open: boolean; close: () => void }) => {
          // eslint-disable-next-line
          useEffect(() => {
            if (!open) {
              const [startDate, endDate] = selectedValue
              if (startDate !== null && endDate === null) handleCancel()
              close()
            }
          }, [open])

          // eslint-disable-next-line
          useEffect(() => {
            setDateStyles(calendarRef, today)
          }, [calendarRef?.current, open])

          return (
            <>
              <Popover.Button disabled={disabled} className="w-full">
                {label && (
                  <label
                    className={classNames(
                      'mb-2.5 flex flex-start text-xs text-cream uppercase text-left',
                    )}
                  >
                    {label}
                  </label>
                )}
                <div
                  className={classNames(
                    'w-full flex bg-white border-gray-150 border border-l-2 border-l-primary',
                    containerClassNames,
                    {
                      '!border !border-error': isError,
                    },
                  )}
                >
                  <div
                    className={classNames(
                      'flex-1 flex-start px-4 py-3',
                      buttonContainerClassNames,
                    )}
                  >
                    {!label && (
                      <div className="mb-3 text-left text-xs text-gray-light uppercase">
                        {t('Check-In')}
                      </div>
                    )}
                    <div
                      className={classNames(
                        'flex justify-between items-center',
                        buttonClassNames,
                      )}
                    >
                      <span className="text-lg">{checkIn}</span>
                      <CalendarInIcon className="fill-current text-primary" />
                    </div>
                  </div>
                  <div
                    className={classNames(
                      'flex-1 px-4 py-3',
                      buttonContainerClassNames,
                    )}
                  >
                    {!label && (
                      <div className="mb-3 text-left text-xs text-gray-light uppercase">
                        {t('Check-Out')}
                      </div>
                    )}
                    <div
                      className={classNames(
                        'flex justify-between items-center',
                        buttonClassNames,
                      )}
                    >
                      <span className="text-lg">{checkOut}</span>
                      <CalendarOutIcon className="fill-current text-primary" />
                    </div>
                  </div>
                </div>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 transform w-147">
                  <div className="mt-2 bg-[#FAFAFA] shadow-lg">
                    <div className="text-xs pl-4 py-1 bg-primary text-white">
                      {title}
                    </div>
                    <div className="relative">
                      {isLoading && (
                        <div className="absolute top-0 left-0 w-full h-full bg-[#F6F6F6] bg-opacity-80 z-[500] flex items-center justify-center">
                          <Loading
                            className="!text-[#F6F6F6] text-opacity-80"
                            baseClassName="!text-primary"
                          />
                        </div>
                      )}
                      <DateRangeSelect
                        calendarRef={calendarRef}
                        showDoubleView={true}
                        currentActiveStartDate={currentActiveStartDate}
                        selectedValue={selectedValue}
                        today={today}
                        disabledDates={disabledDates}
                        onSelectValue={onSelectValue}
                        handleCancel={handleCancel}
                        setCurrentActiveStartDate={setCurrentActiveStartDate}
                        onClose={close}
                        onOpenMaxNightModal={onOpenMaxNightModal}
                      />
                    </div>
                    <div className="pt-2 pb-4 flex items-center justify-center">
                      <GuidelinePopup />
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )
        }}
      </Popover>
    </div>
  )
}

export default PopupPicker
