import dayjs from 'lib/dayjs'

import Payment from 'payment'
function clearNumber(value = '') {
  return value.replace(/\D+/g, '')
}

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value
  }

  const issuer = Payment.fns.cardType(value)
  const clearValue = clearNumber(value)
  const values: { [key: string]: any } = {
    amex: `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      10,
    )} ${clearValue.slice(10, 15)}`,
    dinnersclub: `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      10,
    )} ${clearValue.slice(10, 14)}`,
    default: `${clearValue.slice(0, 4)} ${clearValue.slice(
      4,
      8,
    )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`,
  }

  return (values?.[issuer] || values['default']).trim()
}

export function formatCVC(value: string) {
  const clearValue = clearNumber(value)
  const maxLength = 4

  return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value: string) {
  const clearValue = clearNumber(value)

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
  }

  return clearValue
}

export function cardTypeFromNumber(cardNumber: string) {
  if (Payment.fns.validateCardNumber(cardNumber)) {
    return Payment.fns.cardType(cardNumber)
  }
  return null
}

export function validateExpirationDate(value: string) {
  return Payment.fns.validateCardExpiry(value)
}

export function validateCvc(value: string, cardType: string) {
  return Payment.fns.validateCardCVC(value, cardType)
}

// NOTE: default expiry time is MM/YY
// reformat to YYMM
export function reformatExpiryTime(expiry: string) {
  return dayjs(`01/${expiry}`, 'DD/MM/YY').format('YYMM')
}

// NOTE: cardNumber have whitespace
// remove all space
export function reformatCardNumber(cardNumber: string) {
  return cardNumber?.replace(/\s/g, '')
}
