export const sizeStyles = {
  default: 'py-3.5 px-4 text-base',
  sm: 'py-2 px-4 text-sm',
}

export const themeStyles = {
  dark: 'bg-gray-dark text-gray-50 focus:text-white disabled:text-gray-800',
  light: 'bg-[#FAFAFA] text-gray-dark placeholder-gray-50 disabled:bg-gray-100',
}

export const borderStyles = {
  dark: 'border border-secondary focus:border-yellow-50 disabled:border-secondary',
  light: 'border border-gray-150 focus:border-primary',
  error: 'border border-error focus:border-error disabled:border-error',
}
