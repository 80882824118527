'use client'

import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'

import dayjs from 'lib/dayjs'
import classNames from 'classnames'

import ReactDatePicker from 'react-date-picker'

import ArrowLeft from 'public/icons/arrow-left.svg'
import ArrowRight from 'public/icons/arrow-right.svg'
import CalendarIcon from 'public/icons/calendar.svg'

import { useParams } from 'next/navigation'

export interface DatePickerProps {
  label?: string
  value?: Date
  onChange?: (value: Date) => void
  buttonClassNames?: string
  isError?: boolean
  errorMessage?: string
  showTimeInput?: boolean
  timeInputLabel?: string
  placeHolder?: string
  maxDate?: Date
}

const DatePicker: React.FunctionComponent<DatePickerProps> = ({
  label,
  value = null,
  onChange,
  buttonClassNames,
  isError,
  errorMessage,
  placeHolder,
  ...rest
}) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return (
    <div>
      {label && (
        <div className="block text-xs text-secondary mb-3 uppercase">
          {label}
        </div>
      )}
      <div className="relative md:hidden">
        <input
          type="date"
          value={dayjs(value).format('YYYY-MM-DD')}
          className={classNames(
            'min-h-13.5 w-full bg-[#FAFAFA] text-gray-dark border border-gray-150 py-3 px-4 focus:outline-none pl-12',
            buttonClassNames,
            {
              '!border !border-error': isError,
            },
          )}
          onChange={(event) =>
            event.target.value &&
            onChange &&
            onChange(new Date(event.target.value))
          }
        />
        <div className="absolute top-0 flex items-center h-full left-4">
          <CalendarIcon />
        </div>
      </div>
      <div className="hidden md:block">
        <Popover className="relative w-full">
          {({ open, close }: { open: boolean; close: () => void }) => {
            return (
              <>
                <Popover.Button
                  className={classNames(
                    'w-full bg-[#FAFAFA] border border-gray-150',
                    buttonClassNames,
                    {
                      '!border !border-error': isError,
                    },
                  )}
                >
                  <div className="flex-1 flex-start py-3 px-4">
                    <div className="mt-1 flex items-center">
                      <CalendarIcon />
                      <span className="ml-3 pt-0.5 text-gray-dark">
                        {!value || !locale
                          ? placeHolder
                          : formatDate(value, locale)}
                      </span>
                    </div>
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 transform w-80 mt-1">
                    <div className="ml-2 w-0 h-0 border-l-6 border-l-transparent border-r-6 border-r-transparent border-b-6 border-b-gray-800"></div>
                    <div className="bg-[#FAFAFA] p-4">
                      <ReactDatePicker
                        value={value}
                        locale={locale}
                        calendarType="US"
                        nextLabel={
                          <ArrowRight className="fill-current text-gray-dark" />
                        }
                        prevLabel={
                          <ArrowLeft className="fill-current text-gray-dark" />
                        }
                        isOpen={open}
                        onChange={(newValue: any) => {
                          onChange && onChange(newValue)
                          close()
                        }}
                        formatDay={(_: any, date: Date) =>
                          dayjs(date).get('date').toString()
                        }
                        {...rest}
                      />
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )
          }}
        </Popover>
      </div>
      {isError && errorMessage && (
        <p className="mt-2 text-error text-xs">{errorMessage}</p>
      )}
    </div>
  )
}

const formatDate = (date: Date | null, locale: string) => {
  return dayjs(date)
    .locale(['zh-Hans', 'zh-Hant'].includes(locale) ? 'zh-cn' : locale)
    .format('ll')
}

export default DatePicker
