'use client'

import { useState, useCallback, ReactElement } from 'react'
import classNames from 'classnames'
import EyeIcon from 'public/icons/eye.svg'
import EyeOffIcon from 'public/icons/eye-off.svg'
import { sizeStyles, themeStyles, borderStyles } from './styles'
import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
} from 'utils/paymentFormat'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  labelClassNames?: string
  label?: string | React.ReactNode
  cardInputType?: 'number' | 'expiry' | 'cvc' | 'cardName' | null
  disabled?: boolean
  inputSize?: 'default' | 'sm'
  theme?: 'dark' | 'light'
  placeHolder?: string
  isError?: boolean
  errorMessage?: string
  Icon?: ReactElement
  containerClassName?: string
  onChangeText?: (value: string) => void
  inputClassName?: string
}

const leftIconClassNames =
  'absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none'
const rightIconClassNames =
  'absolute inset-y-0 right-0 flex items-center mr-4 pointer-events-none'

const Input: React.FunctionComponent<InputProps> = ({
  label,
  labelClassNames,
  type,
  value,
  onChangeText,
  disabled = false,
  inputSize = 'default',
  cardInputType,
  theme = 'light',
  placeHolder,
  isError = false,
  errorMessage,
  Icon,
  containerClassName,
  inputClassName,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const ShowPasswordIcon = showPassword ? EyeOffIcon : EyeIcon

  const formatCardInput = useCallback(
    (value: any) => {
      const formats = {
        number: () => formatCreditCardNumber(value),
        expiry: () => formatExpirationDate(value),
        cvc: () => formatCVC(value),
        cardName: () => value.toUpperCase(),
        default: () => {},
      }
      return formats?.[cardInputType || 'default']()
    },
    [cardInputType],
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (cardInputType) {
      value = formatCardInput(e.target.value)
    }
    onChangeText && onChangeText(value)
  }

  return (
    <div className={containerClassName}>
      {label && (
        <div
          className={classNames(
            'block text-xs text-secondary mb-3 uppercase tracking-widest',
            labelClassNames,
          )}
        >
          {label}
        </div>
      )}
      <div>
        {type === 'password' && (
          <div className="w-full">
            <div className="relative">
              <div className="absolute h-[54px] w-[48px] right-0">
                <div className={rightIconClassNames}>
                  <ShowPasswordIcon
                    className="cursor-pointer pointer-events-auto"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="absolute h-[54px] w-fit bg-green">
          {Icon && <div className={leftIconClassNames}>{Icon}</div>}
        </div>
        <input
          disabled={disabled}
          value={value}
          className={classNames(
            sizeStyles[inputSize],
            isError ? themeStyles.error : themeStyles[theme],
            'block w-full focus:outline-none',
            {
              [borderStyles.error]: isError,
              [borderStyles[theme]]: !isError,
              'pl-13': !!Icon,
              'pr-13': type === 'password',
            },
            inputClassName,
          )}
          onChange={handleChange}
          type={type === 'password' && !!showPassword ? 'text' : type}
          placeholder={placeHolder}
          {...rest}
        />
      </div>
      {isError && errorMessage && (
        <p className="mt-2 text-xs text-error">{errorMessage}</p>
      )}
    </div>
  )
}

export default Input
