import classNames from 'classnames'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'

import { useTranslation } from 'app/i18n/client'

import HelpCircleIcon from 'public/icons/help-circle.svg'
import CrossIcon from 'public/icons/cross.svg'
import { useParams } from 'next/navigation'

const ChildrenRulePopup: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return (
    <Popover.Group as="nav" className="flex space-x-10 mt-1">
      <Popover className="relative">
        {({ close }: { close: () => void }) => {
          return (
            <>
              <Popover.Button className="focus:outline-none">
                <HelpCircleIcon className="stroke-current text-priamary" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-[101] transform w-68 left-0 bottom-0 mb-8 -ml-4 lg:-ml-12">
                  <div className="bg-primary-light text-gray-dark rounded-2xl lg:rounded-3.5xl py-4 pr-10 pl-6 relative">
                    <ul
                      className={classNames(
                        'mt-3 text-xs lg:text-sm list-disc space-y-1',
                        locale === 'en' && 'space-x-1',
                      )}
                    >
                      <li className="text-xs lg:text-sm list-disc text-left">
                        {t(
                          'Children over 6 years old will be charged the same fee as adults, so please include them in the number of adults when searching.',
                        )}
                      </li>
                      <li className="text-xs lg:text-sm list-disc text-left">
                        {t(
                          'One child under 6 years old can use existing bed for free.',
                        )}
                      </li>
                    </ul>
                    <div
                      className="absolute right-5 top-4.5 cursor-pointer"
                      onClick={() => close()}
                    >
                      <CrossIcon className="fill-current" />
                    </div>
                  </div>
                  <div className="ml-4 lg:ml-12 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-primary-light"></div>
                </Popover.Panel>
              </Transition>
            </>
          )
        }}
      </Popover>
    </Popover.Group>
  )
}

export default ChildrenRulePopup
