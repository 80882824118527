'use client'

import classNames from 'classnames'

import Loading from '../Loading'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'default' | 'sm'
  variant?: 'primary' | 'secondary' | 'white'
  loading?: boolean
  disable?: boolean
  className?: string
  loadingClassName?: string
  loadingSpinerClassName?: string
}

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  size = 'default',
  loading = false,
  disabled = false,
  type = 'button',
  variant = 'primary',
  className,
  loadingClassName,
  loadingSpinerClassName,
  ...rest
}) => {
  const sizes = {
    default: 'px-8 py-4 text-base font-semibold',
    sm: 'text-xs font-medium px-3 py-2',
  }
  const variants = {
    primary: 'text-white bg-primary',
    secondary: 'bg-gray-600',
    white: 'bg-white border-gray-100 border text-secondary',
  }

  return (
    <button
      type={type}
      className={classNames({
        'inline-flex items-center justify-center focus:outline-none': true,
        [sizes[size]]: true,
        [variants[variant]]: true,
        '!bg-gray-100 !text-white': disabled,
        '!px-12': loading && size === 'default',
        '!px-8': loading && size === 'sm',
        [className || '']: true,
      })}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <div
          className={classNames({
            'py-3': size === 'default',
            'py-2': size === 'sm',
          })}
        >
          <Loading
            size={size}
            className={loadingClassName || (disabled ? '!text-gray-100' : '')}
            baseClassName={
              loadingSpinerClassName || (disabled ? '!text-primary' : '')
            }
          />
        </div>
      ) : (
        children
      )}
    </button>
  )
}

export default Button
