import classNames from 'classnames'
import CrossIcon from 'public/icons/cross.svg'

export interface ModalHeaderProps {
  label: string | React.ReactNode
  onClose?: () => void
  labelClassName?: string
}

const ModalHeader: React.FunctionComponent<ModalHeaderProps> = ({
  label,
  onClose,
  labelClassName,
}) => {
  return (
    <div className="flex justify-between items-center">
      <div className={classNames('text-[22px] font-lora', labelClassName)}>
        {label}
      </div>
      {onClose && (
        <div className="cursor-pointer">
          <CrossIcon
            className="fill-current text-gray-light"
            onClick={onClose}
          />
        </div>
      )}
    </div>
  )
}

export default ModalHeader
