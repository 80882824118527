'use client'

import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'

import MainView from './MainView'
import ThumbView from './ThumbView'

import { ImageCarouselProps } from './ImageCarousel.props'

const EmblaCarousel: React.FunctionComponent<ImageCarouselProps> = ({
  name,
  images,
  mainViewClassNames,
  mainViewContainerClassNames,
  mainViewStyles,
  thumnailClassNames,
  thumbViewHeighClassNames,
  thumbViewWidthClassNames,
  backClassNames,
  nextClassNames,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [mainViewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
  })

  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
  })

  const onThumbClick = useCallback(
    (index: any) => {
      if (!embla || !emblaThumbs) return
      embla.scrollTo(index)
    },
    [embla, emblaThumbs],
  )

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return
    setSelectedIndex(embla.selectedScrollSnap())
    emblaThumbs.scrollTo(embla.selectedScrollSnap())
  }, [embla, emblaThumbs, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  useEffect(() => {
    if (name) {
      onThumbClick(0)
    }
  }, [name])

  return (
    <>
      <MainView
        viewportRefType={mainViewportRef}
        images={images}
        mainClassNames={mainViewClassNames}
        mainContainerClassNames={mainViewContainerClassNames}
        mainStyles={mainViewStyles}
      />
      <ThumbView
        selectedIndex={selectedIndex}
        viewportRefType={thumbViewportRef}
        images={images}
        onSelect={onThumbClick}
        heighClassNames={thumbViewHeighClassNames}
        widthClassNames={thumbViewWidthClassNames}
        containerClassNames={thumnailClassNames}
        backClassNames={backClassNames}
        nextClassNames={nextClassNames}
      />
    </>
  )
}

export default EmblaCarousel
