import { useQuery } from '@tanstack/react-query'
import { useParams } from 'next/navigation'
import {
  FETCH_AVAILABILITY_CALENDAR,
  fetchAvailabilityCalendar,
} from './fetchAvailabilityCalendar'

const useQueryAvailabilityCalendar = ({
  year_month,
  nights,
  hotelId,
  rooms,
}: {
  year_month: string
  nights: number
  hotelId?: number
  rooms: {
    adults: number
    children: number
  }[]
}) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return useQuery({
    queryKey: [
      FETCH_AVAILABILITY_CALENDAR,
      { year_month, nights, hotelId, rooms, locale },
    ],
    queryFn: fetchAvailabilityCalendar(),
    enabled: !!hotelId && !!year_month && !!nights,
  })
}

export default useQueryAvailabilityCalendar
