'use client'

import classNames from 'classnames'

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface ModalProps {
  open: boolean
  position?: 'middle' | 'bottom'
  onClose: () => void
  containerClassName?: string
  modalClassName?: string
  children: React.ReactNode
}

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  position = 'middle',
  onClose,
  containerClassName,
  modalClassName,
  children,
}) => {
  const positionClassName = {
    middle: 'items-center',
    bottom: 'items-end',
  }

  const wrapperClassName = {
    middle: 'inset-0',
    bottom: 'bottom-0 left-0 w-screen h-screen',
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames(
          'fixed z-10',
          wrapperClassName[position],
          modalClassName,
        )}
        onClose={onClose}
      >
        <div
          className={classNames(
            'flex justify-center min-h-screen pt-4 px-4 pb-20 text-center',
            containerClassName,
            positionClassName[position],
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-4"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
