'use client'

import classNames from 'classnames'
import LoadingIcon from 'public/icons/loading.svg'
import LoadingSmIcon from 'public/icons/loading-sm.svg'
import LoadingSpinerIcon from 'public/icons/loading-spinner.svg'
import LoadingSpinerSmIcon from 'public/icons/loading-spinner-sm.svg'

interface LoadingProps {
  className?: string
  baseClassName?: string
  size?: 'default' | 'sm'
}

const Loading: React.FunctionComponent<LoadingProps> = ({
  className,
  baseClassName,
  size = 'default',
}) => {
  const loadingClassName = classNames(
    'absolute stroke-current text-white opacity-80',
    baseClassName,
  )
  const spinnerClassName = classNames(
    'animate-spin absolute fill-current text-primary opacity-80',
    className,
  )

  return (
    <div className="relative flex items-center justify-center">
      {size === 'sm' ? (
        <>
          <LoadingSmIcon className={loadingClassName} />
          <LoadingSpinerSmIcon className={spinnerClassName} />
        </>
      ) : (
        <>
          <LoadingIcon className={loadingClassName} />
          <LoadingSpinerIcon className={spinnerClassName} />
        </>
      )}
    </div>
  )
}

export default Loading
