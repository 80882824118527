'use client'

import Checkbox from 'app/components/atoms/Checkbox'
import { OptionGroupProps } from '..'

const SpecialConditionPanel: React.FunctionComponent<{
  optionsGroups: OptionGroupProps[]
}> = ({ optionsGroups }) => {
  return (
    <>
      {optionsGroups?.map((optionGroup) =>
        optionGroup.options && optionGroup.options.length > 0 ? (
          <>
            <div className="py-4 px-6 lg:px-8.5 bg-primary-light flex items-center">
              {optionGroup.Icon}
              <div className="ml-5 text-lg">{optionGroup.title}</div>
            </div>
            <div className="py-8 px-6 lg:px-8.5 grid grid-cols-2 gap-y-8 gap-x-16">
              {optionGroup.options?.map((option) => (
                <Checkbox
                  key={`amenity-${option.value}-${option.checked}`}
                  label={<div>{option.label}</div>}
                  name={option.value}
                  checked={option.checked}
                  disabled={optionGroup.disabled}
                  onChange={() =>
                    optionGroup.onCheck && optionGroup.onCheck(option.value)
                  }
                />
              ))}
            </div>
          </>
        ) : null,
      )}
    </>
  )
}

export default SpecialConditionPanel
