import axios, { AxiosResponse } from 'axios'

import cookieCutter from 'cookie-cutter'

import { isTokenExpired } from 'lib/utils'
import { CLIENT_SESSION_KEY } from 'utils/constants'

export const getClientSession = async () => {
  const token: string | null = cookieCutter.get(CLIENT_SESSION_KEY)

  if (!token || isTokenExpired(token)) {
    const res: AxiosResponse<{ clientSession: string }> = await axios.get(
      '/api/client-session',
    )
    const token = res?.data?.clientSession

    cookieCutter.set(CLIENT_SESSION_KEY, token, {
      secure: true,
      sameSite: 'lax',
      path: '/',
    })

    return token
  }

  return token
}

export const deleteClientSession = () => {
  cookieCutter.set(CLIENT_SESSION_KEY, '', {
    secure: true,
    sameSite: 'lax',
    expires: new Date(0),
    path: '/',
  })
}
