export const sizeStyles = {
  default: 'py-3.5 px-4 text-base',
  sm: 'py-2 px-4 text-sm',
}

export const themeStyles = {
  dark: 'bg-gray-dark text-gray-50 focus:text-white disabled:text-gray-800',
  light: 'bg-[#FAFAFA] text-gray-dark disabled:bg-gray-100',
  error: 'bg-[#E74C3C] bg-opacity-15 text-gray-dark',
}

export const borderStyles = {
  dark: 'border border-secondary focus:border-yellow-50 disabled:border-secondary',
  light: 'border-gray-150 border',
  error: 'border border-error focus:border-error disabled:border-error',
}
