'use client'

import classNames from 'classnames'
import CheckIcon from 'public/icons/check.svg'
import CheckLgIcon from 'public/icons/check-lg.svg'
import { ReactElement } from 'react'

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  containerSize?: 'base' | 'md' | 'lg'
  label?: string | ReactElement
  name: string
  checked?: boolean
  disabled?: boolean
  isError?: boolean
  errorMessage?: string
  containerClassName?: string
  textClassName?: string
  inputWraperClassName?: string
  inputContainerClassName?: string
  inputContainerErrorClassName?: string
  iconContainerClassName?: string
  iconUncheckClassName?: string
  labelContainerClassName?: string
  labelContainerErrorClassName?: string
  onChanged?: (selected: boolean) => void
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  label,
  containerSize = 'base',
  name,
  checked = false,
  disabled,
  isError,
  errorMessage,
  containerClassName,
  inputWraperClassName,
  inputContainerClassName,
  inputContainerErrorClassName,
  iconContainerClassName,
  iconUncheckClassName,
  labelContainerClassName,
  labelContainerErrorClassName,
  textClassName,
  onChanged,
  ...rest
}) => {
  const widthClassName = {
    base: 'w-4.5',
    md: 'w-6',
    lg: 'w-8',
  }

  const heightClassName = {
    base: 'h-4.5',
    md: 'h-6',
    lg: 'h-8',
  }

  const iconClassName =
    'stroke-current text-white cursor-pointer pointer-events-auto'

  const iconType = {
    base: <CheckIcon className={iconClassName} />,
    md: <CheckIcon className={iconClassName} />,
    lg: <CheckLgIcon className={iconClassName} />,
  }

  return (
    <div className={containerClassName}>
      <div className={classNames('flex items-start', inputWraperClassName)}>
        <div
          className={classNames(
            'flex items-center mt-0.25',
            isError ? inputContainerErrorClassName : inputContainerClassName,
          )}
        >
          <input
            id={name}
            name={name}
            defaultChecked={checked}
            disabled={disabled}
            onChange={() => {
              onChanged && onChanged(!checked)
            }}
            type="checkbox"
            className={classNames(
              'opacity-0 absolute',
              heightClassName[containerSize],
              widthClassName[containerSize],
            )}
            {...rest}
          />
          <div
            className={classNames(
              'flex shrink-0 justify-center items-center',
              checked
                ? 'bg-primary'
                : iconUncheckClassName || 'bg-white border border-primary',
              { 'mr-2': label },
              heightClassName[containerSize],
              widthClassName[containerSize],
              {
                'border-error': isError,
                'border-secondary': !isError && disabled,
                'border-gray-light': !isError && !disabled,
              },
              iconContainerClassName,
            )}
          >
            {iconType[containerSize]}
          </div>
        </div>
        <div
          className={classNames(
            'text-sm',
            isError ? labelContainerErrorClassName : labelContainerClassName,
          )}
        >
          {label && (
            <label
              htmlFor={name}
              className={classNames('text-gray-dark', textClassName)}
            >
              {label}
            </label>
          )}
        </div>
      </div>
      {isError && errorMessage && (
        <p className="mt-2 text-error text-xs">{errorMessage}</p>
      )}
    </div>
  )
}

export default Checkbox
