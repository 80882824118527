import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'

interface MainViewProps {
  images: string[]
  viewportRefType: <ViewportElement extends HTMLElement>(
    instance: ViewportElement | null,
  ) => void
  mainClassNames?: string
  mainContainerClassNames?: string
  mainStyles?: React.CSSProperties
}

const MainView: React.FunctionComponent<MainViewProps> = ({
  viewportRefType,
  images,
  mainClassNames,
  mainContainerClassNames,
  mainStyles,
}) => {
  return (
    <div
      className={classNames('relative px-4 lg:px-0', mainContainerClassNames)}
    >
      <div className="w-full overflow-hidden" ref={viewportRefType}>
        <div className="flex">
          {images.map((image, index) => (
            <div className="min-w-full relative" key={index}>
              <div
                className={classNames(
                  'relative overflow-hidden w-full h-70',
                  mainClassNames,
                )}
                style={mainStyles}
              >
                <Image
                  alt=""
                  src={image}
                  priority
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MainView
