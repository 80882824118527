'use client'

import { forwardRef } from 'react'

import dayjs from 'lib/dayjs'
import classNames from 'classnames'
import DatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css'
import 'dayjs/locale/ja'
import { useParams } from 'next/navigation'

registerLocale('ja', ja)

export interface DateTimePickerProps {
  label?: string
  value?: Date
  onChange?: (value: Date) => void
  buttonClassNames?: string
  isError?: boolean
  errorMessage?: string
  showTimeInput?: boolean
  timeInputLabel?: string
  placeHolder?: string
}

const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = ({
  label,
  value = null,
  onChange,
  buttonClassNames,
  isError,
  errorMessage,
  placeHolder,
  ...rest
}) => {
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const CustomInput = forwardRef(
    ({ onClick }: { onClick?: () => void }, ref: any) => (
      <div className="flex items-center">
        <input
          onClick={onClick}
          ref={ref}
          readOnly
          value={value ? dayjs(value).format('lll') : undefined}
          placeholder={placeHolder}
          className={classNames(
            'h-13.5 w-full bg-gray-dark text-gray-50 border border-secondary py-3 focus:outline-none pl-2',
            buttonClassNames,
            {
              '!border !border-error': isError,
            },
          )}
          onChange={(event) => {
            event.target.value &&
              onChange &&
              onChange(new Date(event.target.value))
          }}
        />
      </div>
    ),
  )

  CustomInput.displayName = 'CustomInput'

  return (
    <div>
      {label && (
        <div className="block text-xs text-cream mb-3 uppercase">{label}</div>
      )}
      <DatePicker
        selected={new Date()}
        onChange={(newValue: Date) => {
          onChange && onChange(newValue)
          close()
        }}
        showTimeSelect
        customInput={<CustomInput />}
        locale={locale}
        {...rest}
      />
      {isError && errorMessage && (
        <p className="mt-2 text-error text-xs">{errorMessage}</p>
      )}
    </div>
  )
}

export default DateTimePicker
