import { useState } from 'react'

import classNames from 'classnames'

import { useTranslation } from 'app/i18n/client'

import { Modal } from 'app/components/atoms'
import CalendarIcon from 'public/icons/calendar.svg'

import CalendarSelect from './CalendarSelect'

interface ModalPickerProps {
  value: (Date | null)[]
  label?: string
  containerClassNames?: string
  isError?: boolean
  buttonContainerClassNames?: string
  buttonClassNames?: string
  checkIn: string
  checkOut: string
  currentActiveStartDate: Date
  locale?: string
  disabledDates?: Date[]
  isLoading?: boolean
  disabled?: boolean
  onChange?: (value: (Date | null)[]) => void
  onClick?: () => void
  onOpenMaxNightModal: () => void
  setCurrentActiveStartDate: (value: Date) => void
}

const ModalPicker: React.FunctionComponent<ModalPickerProps> = ({
  label,
  containerClassNames,
  buttonClassNames,
  buttonContainerClassNames,
  isError,
  checkIn,
  checkOut,
  currentActiveStartDate,
  value,
  disabledDates,
  isLoading,
  disabled,
  onChange,
  onClick,
  setCurrentActiveStartDate,
  onOpenMaxNightModal,
}) => {
  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedValue, onSelectValue] = useState(value)

  const showModal = () => setOpenModal(true)
  const closeModal = () => setOpenModal(false)

  const handleCancel = () => {
    onChange && onChange(value)
    onSelectValue(value)
  }

  const handleClose = () => {
    handleCancel()
    closeModal()
  }

  const handleConfirm = () => {
    onChange && onChange(selectedValue as Array<Date>)
    closeModal()
  }

  const handleReset = () => {
    handleCancel()
    closeModal()
  }

  return (
    <div
      className={classNames('w-full', disabled && 'opacity-70')}
      onClick={onClick}
    >
      {label && (
        <label
          className={classNames(
            'mb-2.5 flex flex-start text-xs text-cream uppercase text-left',
          )}
        >
          {label}
        </label>
      )}
      <div
        onClick={!disabled ? showModal : undefined}
        className={classNames(
          'w-full flex bg-white h-full border-gray-150 border border-l-2 border-l-primary',
          containerClassNames,
          {
            '!border !border-error': isError,
          },
        )}
      >
        <div
          className={classNames(
            'flex-1 flex-start px-2 lg:px-4 py-3',
            buttonContainerClassNames,
          )}
        >
          {!label && (
            <div className="mb-2 text-left text-xxs text-gray-light uppercase">
              {t('Check-In')}
            </div>
          )}
          <div
            className={classNames(
              'flex justify-between items-center text-sm',
              buttonClassNames,
            )}
          >
            <span className="text-md lg:text-lg">{checkIn}</span>
          </div>
        </div>
        <div
          className={classNames(
            'flex-1 border-gray-700 px-2 lg:px-4 py-3',
            buttonContainerClassNames,
          )}
        >
          {!label && (
            <div className="mb-2 text-left text-xxs text-gray-light uppercase">
              {t('Check-Out')}
            </div>
          )}
          <div
            className={classNames(
              'flex justify-between items-center text-sm',
              buttonClassNames,
            )}
          >
            <span className="text-md lg:text-lg">{checkOut}</span>
          </div>
        </div>
        <div className="text-gray-light flex items-center pr-3 mt-3">
          <CalendarIcon className="fill-current text-primary" />
        </div>
      </div>
      <Modal
        position="bottom"
        open={openModal}
        onClose={handleClose}
        containerClassName="!pt-0 !px-0 !pb-0 !w-full !h-full"
      >
        <div className="!w-full max-h-[80%] h-[575px]">
          <CalendarSelect
            currentActiveStartDate={currentActiveStartDate}
            selectedValue={selectedValue}
            setCurrentActiveStartDate={setCurrentActiveStartDate}
            disabledDates={disabledDates}
            isLoading={isLoading}
            handleReset={handleReset}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            onSelectValue={onSelectValue}
            onOpenMaxNightModal={onOpenMaxNightModal}
          />
        </div>
      </Modal>
    </div>
  )
}

export default ModalPicker
