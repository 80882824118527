import useQueryAvailabilityCalendar from 'app/hooks/useQueryAvailabilityCalendar'
import dayjs from 'lib/dayjs'

const useAvaialbleCalendar = (
  currentActiveStartDate: Date,
  rooms: {
    adults: number
    children: number
  }[],
  hotelId?: number,
) => {
  const previousMonth = dayjs(currentActiveStartDate)
    .add(-1, 'month')
    .format('YYYY/MM')

  const currentMonth = dayjs(currentActiveStartDate).format('YYYY/MM')
  const nextMonth = dayjs(currentActiveStartDate)
    .add(1, 'month')
    .format('YYYY/MM')
  const next2Month = dayjs(currentActiveStartDate)
    .add(2, 'month')
    .format('YYYY/MM')

  const {
    data: previousMonthData,
    isInitialLoading: isLoadingPreviousMonth,
    isError: isErrorPreviousMonth,
  } = useQueryAvailabilityCalendar({
    year_month: previousMonth,
    hotelId,
    nights: 1,
    rooms,
  })

  const {
    data: currentMonthData,
    isInitialLoading: isLoadingCurrentMonth,
    isError: isErrorCurrentMonth,
  } = useQueryAvailabilityCalendar({
    year_month: currentMonth,
    hotelId,
    nights: 1,
    rooms,
  })

  const {
    data: nextMonthData,
    isInitialLoading: isLoadingNextMonth,
    isError: isErrorNextMonth,
  } = useQueryAvailabilityCalendar({
    year_month: nextMonth,
    hotelId,
    nights: 1,
    rooms,
  })

  const {
    data: nextTwoMonthData,
    isInitialLoading: isLoadingNextTwoMonth,
    isError: isErrorNextTwoMonth,
  } = useQueryAvailabilityCalendar({
    year_month: next2Month,
    hotelId,
    nights: 1,
    rooms,
  })

  const disabledDates =
    previousMonthData && currentMonthData && nextMonthData && nextTwoMonthData
      ? [
          ...(previousMonthData?.disabledDates || []),
          ...(currentMonthData?.disabledDates || []),
          ...(nextMonthData?.disabledDates || []),
          ...(nextTwoMonthData?.disabledDates || []),
        ].map((date: string) => new Date(date))
      : undefined

  return {
    isLoading:
      isLoadingCurrentMonth ||
      isLoadingNextMonth ||
      isLoadingNextTwoMonth ||
      isLoadingPreviousMonth,
    isError:
      isErrorCurrentMonth ||
      isErrorNextMonth ||
      isErrorNextTwoMonth ||
      isErrorPreviousMonth,
    disabledDates,
  }
}

export default useAvaialbleCalendar
