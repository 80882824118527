import classNames from 'classnames'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'

import { useTranslation } from 'app/i18n/client'

import HelpCircleIcon from 'public/icons/help-circle.svg'
import CrossIcon from 'public/icons/cross.svg'
import { useParams } from 'next/navigation'

const ChildrenRulePopup: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  return (
    <Popover.Group as="nav" className="flex space-x-10 mt-1">
      <Popover className="relative">
        {({ close }: { close: () => void }) => {
          return (
            <>
              <Popover.Button className="focus:outline-none space-x-2 flex items-center">
                <HelpCircleIcon className="stroke-current text-primary" />
                <span className="text-primary lg:text-xs text-xxs">
                  {t('How to search for day-use, monthly, and weekly plans')}
                </span>
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className={`absolute z-50 transform lg:w-104 bottom-0 mb-8 lg:-ml-12 -ml-6 ${
                    locale === 'en' || locale === 'ja'
                      ? 'w-86 left-0'
                      : ' w-80 -left-8'
                  } `}
                >
                  <div className="bg-primary-light text-gray-dark rounded-3.5xl py-4 pr-10 pl-10 relative">
                    <ul
                      className={classNames(
                        'mt-3 text-xs lg:text-sm list-disc space-y-1',
                        locale === 'en' && 'space-x-1',
                      )}
                    >
                      <li className="text-xs lg:text-sm list-disc text-left">
                        {t(
                          'For Day Use Plan, please specify the same date for check-in and check-out.',
                        )}
                      </li>
                      <li className="text-xs lg:text-sm list-disc text-left">
                        {t('For Monthly Plan, please specify 30 or 31 nights.')}
                      </li>
                      <li className="text-xs lg:text-sm list-disc text-left">
                        {t(
                          'For Weekly Plan, please specify from 7 nights to 29 nights.',
                        )}
                      </li>
                    </ul>
                    <div
                      className="absolute right-5 top-4.5 cursor-pointer"
                      onClick={() => close()}
                    >
                      <CrossIcon className="fill-current text-gray-dark" />
                    </div>
                  </div>
                  <div className="lg:ml-12 ml-6 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-primary-light"></div>
                </Popover.Panel>
              </Transition>
            </>
          )
        }}
      </Popover>
    </Popover.Group>
  )
}

export default ChildrenRulePopup
