'use client'

import InfoCircleIcon from 'public/icons/info-circle.svg'
import CheckCircleIcon from 'public/icons/check-circle.svg'
import CrossCircleIcon from 'public/icons/cross-circle.svg'
import { Slide, TypeOptions } from 'react-toastify'
import { ToastContainer, toast } from 'react-toastify'
import classNames from 'classnames'

const contextStyles = {
  success: 'text-success',
  error: 'text-error',
  info: 'text-white',
  warning: 'text-[#eab308]',
  default: 'text-white',
}

const icons = {
  success: <CheckCircleIcon className="fill-current text-success" />,
  error: <CrossCircleIcon className="fill-current text-error" />,
  info: <InfoCircleIcon className="fill-current text-[#eab308]" />,
  warning: <InfoCircleIcon className="fill-current text-[#eab308]" />,
  default: <InfoCircleIcon className="fill-current text-[#eab308]" />,
}

const Toast = () => {
  return (
    <div className="absolute">
      <ToastContainer
        closeButton={false}
        transition={Slide}
        position={toast.POSITION.TOP_RIGHT}
        toastClassName={(context) =>
          classNames(
            'bg-white my-4 mx-4 lg:mx-0 px-4 py-2.5 border border-gray-150 flex items-center justify-end text-sm',
            contextStyles[context?.type || 'default'],
          )
        }
        icon={({ type }: any) => {
          const seletedType: TypeOptions = type as TypeOptions
          return icons[seletedType || 'default']
        }}
        hideProgressBar={true}
      />
    </div>
  )
}

export default Toast
