import { useTranslation } from 'app/i18n/client'
import { useEffect, useState } from 'react'

import { Button, NumberSelect } from 'app/components/atoms'
import ChildrenRulePopup from '../ChildrenRulePopup'
import classNames from 'classnames'
import { useParams } from 'next/navigation'
import { RoomGuestInfo } from '..'

export interface PanelProps {
  maxRoom?: number
  maxAdult?: number
  maxChildren?: number
  value?: RoomGuestInfo[]
  open?: boolean
  handleConfirm: (selectedValue: RoomGuestInfo[]) => void
  handleCancel: () => void
}

const defaultValue: RoomGuestInfo[] = [
  {
    index: 1,
    numberOfAdult: 1,
    numberOfChildren: 0,
  },
]

const MAX_ADULT = 6

const Panel: React.FunctionComponent<PanelProps> = ({
  maxRoom,
  maxAdult,
  maxChildren,
  value,
  open,
  handleConfirm,
  handleCancel,
}) => {
  const { t } = useTranslation()
  const params = useParams<{ locale: string }>()
  const { locale } = params || {}

  const [selectedValue, setSelectedValue] = useState<Array<RoomGuestInfo>>(
    value || defaultValue,
  )

  useEffect(() => {
    if (!open) {
      setSelectedValue(value || defaultValue)
    }
  }, [open])

  const numberOfSelecedRooms = selectedValue.length

  const handleRoomIncreased = () => {
    setSelectedValue([
      ...selectedValue,
      {
        index: Number(numberOfSelecedRooms) + 1,
        numberOfAdult: 1,
        numberOfChildren: 0,
      },
    ])
  }

  const handleRoomDecreased = () => {
    if (numberOfSelecedRooms > 1) {
      setSelectedValue(selectedValue.slice(0, -1))
    }
  }

  const handleAdultIncreased = (roomIndex: number) => () => {
    setSelectedValue(
      selectedValue.map((room: RoomGuestInfo) => {
        if (room.index !== roomIndex) return room

        return {
          ...room,
          numberOfAdult: Number(room.numberOfAdult) + 1,
        }
      }),
    )
  }

  const handleAdultDecreased = (roomIndex: number) => () => {
    setSelectedValue(
      selectedValue.map((room: RoomGuestInfo) => {
        if (room.index !== roomIndex) return room
        const numberOfAdult =
          Number(room.numberOfAdult) > 1
            ? Number(room.numberOfAdult) - 1
            : Number(room.numberOfAdult)
        return {
          ...room,
          numberOfAdult,
          numberOfChildren: Math.min(numberOfAdult, room?.numberOfChildren),
        }
      }),
    )
  }

  const handleChildIncreased = (roomIndex: number) => () => {
    setSelectedValue(
      selectedValue.map((room: RoomGuestInfo) => {
        if (room.index !== roomIndex) return room

        return {
          ...room,
          numberOfChildren: Number(room.numberOfChildren) + 1,
        }
      }),
    )
  }

  const handleChildDecreased = (roomIndex: number) => () => {
    setSelectedValue(
      selectedValue.map((room: RoomGuestInfo) => {
        if (room.index !== roomIndex) return room

        return {
          ...room,
          numberOfChildren:
            Number(room.numberOfChildren) > 0
              ? Number(room.numberOfChildren) - 1
              : Number(room.numberOfChildren),
        }
      }),
    )
  }

  const onCancel = () => {
    setSelectedValue(value || defaultValue)
    handleCancel()
  }

  return (
    <>
      <div className="px-6 md:px-8">
        <div className="py-6 flex items-center border-b border-gray-100 lg:border-b-0">
          <div className="text-sm md:text-base text-primary uppercase">
            {t('Room Number')}
          </div>
          <div className="flex-1 flex items-center justify-end">
            <div className="ml-4 text-yellow-50 uppercase text-lg w-25 md:w-29.5">
              <NumberSelect
                value={numberOfSelecedRooms}
                onIncreased={handleRoomIncreased}
                onDecreased={handleRoomDecreased}
                minValue={1}
                maxValue={maxRoom}
              />
            </div>
            <div className="ml-3 select-none text-xxs uppercase w-13">
              {t('Room (s)')}
            </div>
          </div>
        </div>
        <div className="mt-9 mb-4 mr-16 select-none uppercase text-xs col-span-2 text-right">
          {t('Number of people')}
        </div>
        {selectedValue.map((roomDetail) => (
          <div
            key={`room-detail-index-${roomDetail.index}`}
            className="py-4  border-t border-b border-gray-100 lg:border-t-0 lg:border-b-0"
          >
            <div className="space-y-6.5">
              <div className="flex">
                <div className="text-sm md:text-base select-none text-gray-dark uppercase h-7.5 flex items-center">
                  {t('Room {{index}}', { index: roomDetail?.index })}
                </div>
                <div className="flex-1 flex items-center justify-end">
                  <div className="text-sm md:text-base select-none uppercase">
                    {t('Adult')}
                  </div>
                  <div className="ml-4 text-yellow-50 uppercase text-lg w-25 md:w-29.5">
                    <NumberSelect
                      value={roomDetail?.numberOfAdult}
                      onIncreased={handleAdultIncreased(roomDetail.index)}
                      onDecreased={handleAdultDecreased(roomDetail.index)}
                      minValue={1}
                      maxValue={maxAdult || MAX_ADULT}
                    />
                  </div>
                  <div className="ml-3 select-none text-xxs uppercase w-13">
                    {t('Person(s)')}
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end">
                <ChildrenRulePopup />
                <div
                  className={classNames(
                    locale === 'ja' && 'flex space-x-1',
                    'text-sm md:text-base ml-2 select-none text-right',
                  )}
                >
                  <p className="uppercase">{t('Child')}</p>
                  <p>{t('(under 6 years old)')}</p>
                </div>
                <div className="ml-4 text-yellow-50 uppercase text-lg w-25 md:w-29.5">
                  <NumberSelect
                    value={roomDetail?.numberOfChildren}
                    onIncreased={handleChildIncreased(roomDetail.index)}
                    onDecreased={handleChildDecreased(roomDetail.index)}
                    maxValue={
                      maxChildren !== null && maxChildren !== undefined
                        ? maxChildren
                        : roomDetail?.numberOfAdult
                    }
                  />
                </div>
                <div className="ml-3 select-none text-xxs uppercase w-13">
                  {t('Person(s)')}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="hidden md:block px-8 py-4 mt-8 flex items-center text-right w-full space-x-4 border-t border-gray-100">
        <Button
          onClick={onCancel}
          size="sm"
          variant="white"
          className="select-none uppercase"
        >
          {t('Revert')}
        </Button>
        <Button
          onClick={() => handleConfirm(selectedValue)}
          size="sm"
          className="select-none !bg-primary uppercase"
        >
          {t('Confirm')}
        </Button>
      </div>
      <div className="md:hidden flex-1 flex items-end">
        <div className="py-8 px-6 flex justify-between space-x-6 w-full">
          <Button
            onClick={onCancel}
            variant="white"
            className="select-none uppercase !w-full"
          >
            {t('Revert')}
          </Button>
          <Button
            onClick={() => handleConfirm(selectedValue)}
            className="select-none !bg-primary uppercase !w-full"
          >
            {t('Confirm')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Panel
