'use client'

import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useTranslation } from 'app/i18n/client'

import { Modal } from 'app/components/atoms'

import RoomGuestButton from './Button'
import RoomGuestPanel from './Panel'

import type { ReactElement } from 'react'

export type RoomGuestInfo = {
  index: number
  numberOfAdult: number
  numberOfChildren: number
}

export interface RoomGuestSelectProps {
  value?: Array<RoomGuestInfo>
  maxRoom?: number
  maxChildren?: number
  maxAdult?: number
  disabled?: boolean
  onChange?: (value: Array<RoomGuestInfo>) => void
  onClick?: () => void
  containerClassName?: string
  labelClassName?: string
  buttonClassName?: string
  Icon?: ReactElement
  isError?: boolean
  errorMessage?: string
}

const RoomGuestSelect: React.FunctionComponent<RoomGuestSelectProps> = ({
  value,
  maxRoom = 4,
  maxAdult,
  maxChildren,
  disabled,
  onChange,
  onClick,
  containerClassName,
  labelClassName,
  buttonClassName,
  Icon,
  isError,
  errorMessage,
}) => {
  const { t } = useTranslation()

  const [openModal, setOpenModal] = useState<boolean>(false)

  const numberOfRooms = value?.length
  const numberOfPeople = value?.reduce((result, room) => {
    return result + Number(room.numberOfAdult) + Number(room.numberOfChildren)
  }, 0)

  const title = !value
    ? t('Select number of room guest')
    : t('{{numberOfRooms}} Room(s), {{numberOfPeople}} Person(s)', {
        numberOfRooms,
        numberOfPeople,
      })

  const handleConfirm =
    (onClose: () => void) => (selectedValue: RoomGuestInfo[]) => {
      onChange && onChange(selectedValue)
      onClose()
    }

  const showModal = () => !disabled && setOpenModal(true)
  const closeModal = () => setOpenModal(false)

  return (
    <>
      <div
        className={classNames('md:hidden', disabled && 'bg-[#F6F6F6]')}
        onClick={onClick}
      >
        <div className="w-full cursor-pointer" onClick={showModal}>
          <RoomGuestButton
            title={title}
            open={openModal}
            containerClassName={containerClassName}
            labelClassName={labelClassName}
            buttonClassName={buttonClassName}
            Icon={Icon}
            isError={isError}
            errorMessage={errorMessage}
          />
        </div>
        <Modal
          position="bottom"
          open={openModal}
          onClose={closeModal}
          containerClassName="!pt-0 !px-0 !pb-0 !w-full !h-full"
        >
          <div className="flex flex-col bg-white shadow-xl transform transition-all w-full h-3/4 rounded-t-2.5xl">
            <div className="py-8 text-center">
              <h3 className="font-lora text-2xl">{t('Rooms & Guests')}</h3>
            </div>
            <div className="flex flex-col flex-1 h-full overflow-y-auto border-t border-gray-100 lg:border-t-0 text-left">
              <RoomGuestPanel
                value={value}
                open={openModal}
                maxRoom={maxRoom}
                maxAdult={maxAdult}
                maxChildren={maxChildren}
                handleConfirm={handleConfirm(closeModal)}
                handleCancel={closeModal}
              />
            </div>
          </div>
        </Modal>
      </div>
      <div
        className={classNames('hidden md:block', disabled && '!bg-[#F6F6F6]')}
        onClick={onClick}
      >
        <Popover className="relative w-full">
          {({ open, close }: { open: boolean; close: () => void }) => {
            return (
              <>
                <Popover.Button disabled={disabled} className="w-full">
                  <RoomGuestButton
                    title={title}
                    open={open}
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    buttonClassName={buttonClassName}
                    Icon={Icon}
                    isError={isError}
                    errorMessage={errorMessage}
                  />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 transform bg-[#FAFAFA] shadow-lg mt-1 w-122 max-h-102 3xl:max-h-132 text-base overflow-auto focus:outline-none">
                    <RoomGuestPanel
                      maxAdult={maxAdult}
                      maxChildren={maxChildren}
                      value={value}
                      open={open}
                      maxRoom={maxRoom}
                      handleConfirm={handleConfirm(close)}
                      handleCancel={close}
                    />
                  </Popover.Panel>
                </Transition>
              </>
            )
          }}
        </Popover>
      </div>
    </>
  )
}

export default RoomGuestSelect
